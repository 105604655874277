// react
import React, { FunctionComponent } from 'react';

const FooterContacts: FunctionComponent<React.HTMLAttributes<HTMLElement>> = () => (
    <div className="footer-contacts">
        <h5 className="footer-contacts__title">Contact Us</h5>

        <div className="footer-contacts__text">
            Join Vmotive Retail - boost your auto spare parts business and annual income.
        </div>

        <address className="footer-contacts__contacts">
            <dl>
                <dt>Phone Number</dt>
                <dd>
                    <a href="tel:+919311962746">+91 9311962746</a>
                </dd>
            </dl>
            <dl>
                <dt>Email Address</dt>
                <dd>support@vmotive.in</dd>
            </dl>
            <dl>
                <dt>Our Location</dt>
                <dd>B-37, 4th floor, Thinkvalley, Sector-32, Gurugram - 122003 Haryana</dd>
            </dl>
            <dl>
                <dt>Working Hours</dt>
                <dd>Mon-Sat 9:00am - 8:00pm</dd>
            </dl>
        </address>
    </div>
);

export default FooterContacts;
