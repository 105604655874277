// react
import React, { useCallback, useEffect, useMemo } from 'react';
// third-party
import classNames from 'classnames';
import { Controller, FormProvider } from 'react-hook-form';
import { Modal } from 'reactstrap';
// application
import AppLink from '~/components/shared/AppLink';
import InputNumber from '~/components/shared/InputNumber';
import ProductGallery from '~/components/shop/ProductGallery';
import Rating from '~/components/shared/Rating';
import url from '~/services/url';
import { Cross12Svg } from '~/svg';
import { useProductForm } from '~/services/forms/product';
import { useQuickview, useQuickviewClose } from '~/store/quickview/quickviewHooks';
import { formatReviews } from '~/helpers/formatter';
import CurrencyFormat from './CurrencyFormat';
import { useCurrentCartItem } from '~/store/cart/cartHooks';

function Quickview() {
    const quickview = useQuickview();
    const quickviewClose = useQuickviewClose();
    const { product } = quickview;
    const image = useMemo(
        () => (product?.image ? [product?.image || '/images/dummyProduct.png'] : ['/images/dummyProduct.png']),
        [product]
    );
    const productForm = useProductForm(product);
    const cartProduct = useCurrentCartItem(product?.sku_id || '');

    const toggle = useCallback(() => {
        if (quickview.open) {
            quickviewClose();
        }
    }, [quickview.open, quickviewClose]);

    if (!product) {
        return null;
    }

    return (
        <Modal isOpen={quickview.open} toggle={toggle} centered className="quickview">
            <button type="button" className="quickview__close" onClick={quickviewClose}>
                <Cross12Svg />
            </button>

            <FormProvider {...productForm.methods}>
                <form onSubmit={productForm.submit} className="quickview__body">
                    <ProductGallery className="quickview__gallery" layout="quickview" images={image} />

                    <div className="quickview__product">
                        <div className="quickview__product-name">{product.description || product.part_description}</div>
                        <div className="quickview__product-rating">
                            <div className="quickview__product-rating-stars">
                                <Rating value={product.mrp % 3} />
                            </div>
                            <div className="quickview__product-rating-title">
                                {product.rating ? formatReviews(product.rating) : ''}
                            </div>
                        </div>
                        <div className="quickview__product-meta">
                            <table>
                                <tbody>
                                    <tr>
                                        <th>SKU</th>
                                        <td>{product.sku_id}</td>
                                    </tr>
                                    {product.manufacturer && (
                                        <React.Fragment>
                                            <tr>
                                                <th>Brand</th>
                                                <td>
                                                    <AppLink href="/">{product.manufacturer}</AppLink>
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    )}
                                    <tr>
                                        <th>Part Number</th>
                                        <td>{product.part_number}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="quickview__product-description">
                            VMotive is a leading brand in India for all your spare part needs. This is a
                            <strong> {product.description} </strong>
                            which is compatible with {product.variant_string}.
                        </div>
                        <div className="quickview__product-prices-stock">
                            <div className="quickview__product-prices">
                                <React.Fragment>
                                    {product.slashedPrice ? (
                                        <div className="quickview__product-price quickview__product-price--old">
                                            <CurrencyFormat value={product.slashedPrice} />
                                        </div>
                                    ) : null}
                                    <div className="quickview__product-price quickview__product-price--new">
                                        <CurrencyFormat value={product.mrp} />
                                        {/* {product.lot_size > 1 && ` x pack of ${product.lot_size}`} */}
                                    </div>
                                </React.Fragment>
                            </div>
                        </div>

                        <div className="quickview__product-actions">
                            <div className="quickview__product-actions-item quickview__product-actions-item--quantity">
                                <Controller
                                    name="quantity"
                                    rules={{ required: true }}
                                    render={({ field: { ref: fieldRef, ...fieldProps } }) => (
                                        <InputNumber
                                            min={1}
                                            inputRef={fieldRef}
                                            defaultValue={cartProduct?.quantity}
                                            {...fieldProps}
                                        />
                                    )}
                                />
                            </div>
                            <div className="quickview__product-actions-item quickview__product-actions-item--addtocart">
                                <button
                                    type="submit"
                                    className={classNames('btn', 'btn-primary', 'btn-block', {
                                        'btn-loading': productForm.submitInProgress,
                                    })}
                                >
                                    {cartProduct?.quantity ? 'Update cart' : 'Add to cart'}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </FormProvider>

            <AppLink href={url.product(product.sku_id)} className="quickview__see-details">
                See full details
            </AppLink>
        </Modal>
    );
}

export default Quickview;
