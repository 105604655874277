// react
import React from 'react';
// third-party
import classNames from 'classnames';
// application
import AppLink from '~/components/shared/AppLink';
import { IMegamenu } from '~/interfaces/menu';
import { IPartCategory, IPartSubCategory } from '~/store/mobile-menu/mobileMenuTypes';
import url from '~/services/url';

interface Props extends React.HTMLAttributes<HTMLElement> {
    menu: Array<IPartSubCategory> | IMegamenu;
    category: IPartCategory;
    level?: number;
    onItemClick?: () => void;
}

function MegamenuLinks(props: Props) {
    const { menu, level = 0, onItemClick, className, category, ...rootProps } = props;

    const rootClasses = classNames('megamenu-links', className, {
        'megamenu-links--root': level === 0,
    });

    return (
        <ul className={rootClasses} {...rootProps}>
            {Array.isArray(menu) &&
                menu.map((item, linkIndex) => {
                    // const submenu = item?.submenu ?? [];
                    // const hasSubMenu = submenu.length > 0;
                    const linkClasses = classNames('megamenu-links__item');

                    return (
                        <li className={linkClasses} key={linkIndex}>
                            <AppLink
                                className="megamenu-links__item-link"
                                href={url.shopCategoryProducts(category, { subcategory: item.subcategory })}
                                onClick={() => onItemClick && onItemClick()}
                            >
                                {item.subcategory}
                            </AppLink>
                            {/* {hasSubMenu && <MegamenuLinks menu={submenu} level={level + 1} />} */}
                        </li>
                    );
                })}
        </ul>
    );
}

export default React.memo(MegamenuLinks);
