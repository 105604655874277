// application
import { hasHandler } from '~/services/filters';
import { SHOP_NAMESPACE, IShopState } from '~/store/shop/shopTypes';
import {
    SHOP_FETCH_PRODUCTS_LIST_START,
    SHOP_FETCH_PRODUCTS_LIST_SUCCESS,
    SHOP_HYDRATE,
    SHOP_INIT,
    SHOP_RESET_FILTER,
    SHOP_RESET_FILTERS,
    SHOP_SET_OPTION_VALUE,
    ShopAction,
    ShopFetchProductsListSuccessAction,
    ShopResetFilterAction,
    ShopSetOptionValueAction,
    SHOP_SET_FILTERS,
    ShopSetFiltersAction,
} from '~/store/shop/shopActionTypes';

const initialState: IShopState = {
    init: false,
    categorySlug: null,
    category: null,
    productsListIsLoading: true,
    productsList: null,
    options: {},
    filters: {},
    categories: [],
};

function shopReducerFetchProductsListSuccess(
    state: IShopState,
    action: ShopFetchProductsListSuccessAction,
): IShopState {
    return {
        ...state,
        productsListIsLoading: false,
        productsList: action.productsList,
    };
}

function shopReducerSetOptionValue(state: IShopState, action: ShopSetOptionValueAction): IShopState {
    return {
        ...state,
        options: {
            ...state.options,
            // Page based navigation
            page: 1,
            // Cursor based navigation
            before: undefined,
            after: undefined,
            // Current option
            [action.option]: action.value,
        },
    };
}

function shopReducerSetFilters(state: IShopState, action: ShopSetFiltersAction): IShopState {
    const currentFilters = { ...state.filters, ...action.filter };

    const filters: Record<string, string> = {};
    Object.entries(currentFilters).forEach(([key, value]:[string, string]) => {
        if (value) {
            filters[key] = value;
        }
    });

    return {
        ...state,
        options: {
            ...state.options,
            // Page based navigation
            page: 1,
            // Cursor based navigation
            before: undefined,
            after: undefined,
        },
        filters,
    };
}

function shopReducerResetFilter(state: IShopState, action: ShopResetFilterAction): IShopState {
    if (!hasHandler(action.activeFilter.original)) {
        return state;
    }

    return {
        ...state,
        options: {
            ...state.options,
            // Page based navigation
            page: 1,
            // Cursor based navigation
            before: undefined,
            after: undefined,
        },
        filters: {},
    };
}

function shopReducer(state = initialState, action: ShopAction): IShopState {
    switch (action.type) {
    case SHOP_HYDRATE:
        return action.payload[SHOP_NAMESPACE];
    case SHOP_INIT:
        return {
            ...initialState,
            init: true,
            categorySlug: action.categorySlug,
            options: action.options,
            filters: action.filters,
            categories: state.categories || [],
        };
    case SHOP_FETCH_PRODUCTS_LIST_START:
        return { ...state, productsListIsLoading: true };
    case SHOP_FETCH_PRODUCTS_LIST_SUCCESS:
        return shopReducerFetchProductsListSuccess(state, action);
    case SHOP_SET_OPTION_VALUE:
        return shopReducerSetOptionValue(state, action);
    case SHOP_SET_FILTERS:
        return shopReducerSetFilters(state, action);
    case SHOP_RESET_FILTERS:
        return { ...state, options: { ...state.options, page: 1 }, filters: {} };
    case SHOP_RESET_FILTER:
        return shopReducerResetFilter(state, action);
    default:
        return state;
    }
}

export default shopReducer;
