// react
import React from 'react';
import { priceFormatter } from '~/helpers/utils';
// application

interface Props {
    value: number | string;
}

function CurrencyFormat(props: Props) {
    const { value = 0 } = props;

    return (
        <React.Fragment>
            {priceFormatter.format(Number(value))}
        </React.Fragment>
    );
}

export default CurrencyFormat;
