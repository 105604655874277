// application
import { ICategoryV2 } from '~/interfaces/category';
import { IFilterValues, IListOptions } from '~/interfaces/list';
import { IProductsList } from '~/interfaces/product';

export const SHOP_NAMESPACE = 'shop';

export interface IShopState {
    init: boolean;
    categorySlug: string | null;
    category: ICategoryV2 | null;
    categories: ICategoryV2;
    productsListIsLoading: boolean;
    productsList: IProductsList | null;
    options: IListOptions;
    filters: IFilterValues;
}
