// application
import { AppAction } from '~/store/types';
import { IProduct, IProductDetails } from '~/interfaces/product';
import { IOrderDetails } from '~/interfaces/order';

export const UPDATE_CART_ID = 'UPDATE_CART_ID';
export const UPDATE_CART = 'UPDATE_CART';
export const CART_UPDATE_QUANTITIES = 'CART_UPDATE_QUANTITIES';
export const RESET_CART = 'RESET_CART';
export interface UpdateCartIdAction {
    type: typeof UPDATE_CART_ID;
    order_id: number;
}
export interface UpdateCartAction {
    type: typeof UPDATE_CART;
    order: IOrderDetails;
}
export interface CartUpdateQuantitiesAction {
    type: typeof CART_UPDATE_QUANTITIES;
    sku_id: string;
    quantity: number;}

export interface ResetCartAction {
    type: typeof RESET_CART;
}

export type CartAction =
    | CartUpdateQuantitiesAction
    | ResetCartAction
    | UpdateCartIdAction
    | UpdateCartAction;

export type CartThunkAction<T = void> = AppAction<CartAction, T>;
