// application
// @ts-nocheck
import http from '~/helpers/http';
import {
    IBaseCategoryV2, ICategoryV2, ILastCategoryV2, ISubCategoryV2,
} from '~/interfaces/category';
import { MOCKRESPONSE } from './mockResponse';

const combinedCategories = (
    categories: IBaseCategoryV2[],
    subcategories: ISubCategoryV2[] = [],
    subList: ILastCategoryV2[] = [],
): ICategoryV2 => categories.map((item) => {
    const submenu = subcategories
        .filter((subCategory) => subCategory.category_id === item.id)
        .map((subCategory) => {
            const submenu2 = subList.filter((listitem) => listitem.subcategory_id === subCategory.id);
            return {
                ...subCategory,
                submenu: submenu2,
            };
        });
    return {
        ...item,
        submenu,
    };
});

// eslint-disable-next-line import/prefer-default-export
export const getCategories = async (): Promise<ICategoryV2> => {
    const { categories } = await http.get('categories', {}, { stubSuccess: MOCKRESPONSE.categories });
    const { part_subcategories } = await http.get('subcategories', {}, { stubSuccess: MOCKRESPONSE.subcategories });
    const { subList } = await http.get('sublist', {}, { stubSuccess: MOCKRESPONSE.subcategories });
    return Promise.resolve(combinedCategories(categories, part_subcategories, subList));
};

// eslint-disable-next-line import/prefer-default-export
export const getPopularCategories = async (): Promise<ICategoryV2> => {
    const { categories } = await http.get('categories', {}, { stubSuccess: MOCKRESPONSE.categories });
    const { part_subcategories } = await http.get('subcategories', {}, { stubSuccess: MOCKRESPONSE.subcategories });
    const { subList } = await http.get('sublist', {}, { stubSuccess: MOCKRESPONSE.subcategories });
    return Promise.resolve(combinedCategories(categories, part_subcategories, subList));
};
