/* eslint-disable import/prefer-default-export */
export const MOCKRESPONSE = {
    success: { status: 'success' },
    validate: {
        token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoyNDg0MCwiZW1haWwiOiJpaWl0dTE2MTE4QGdtYWlsLmNvbSIsInVzZXJuYW1lIjoiYWYyNTBhNjItM2Q0MC00ZDY5LThiZGQtMDNjMWU1N2FjY2I2IiwiZXhwIjoxNjU1OTYzOTgyfQ.n3zV9fR8VAsQGDholmR57hr07p4j3Hstdm6PIu2zmho',
        user: {
            id: 24840,
            first_name: 'SHUBHAM',
            last_name: 'KAMBOJ',
            phone: '7906348804',
            email: 'iiitu16118@gmail.com',
            city: null,
            photo_url: null,
            is_email_verified: false,
        },
        status: 'success',
    },
    user: {
        first_name: 'Hemant',
        last_name: 'Agrawal',
        avatar: '//www.gravatar.com/avatar/bde30b7dd579b3c9773f80132523b4c3?d=mp&s=88',
        email: 'Hemant.Agrawal@fixcraft.in',
        phone: 8755988217,
        default_address: {
            id: 123,
            first_name: 'Hemant',
            last_name: 'Agrawal',
            company: null,
            address_line_1: 'Near Shiv Mandir',
            address_line_2: 'ABC, Gurgaon',
            city: 'Gurgaon',
            state: 'Haryana',
            pincode: '132005',
            email: 'Hemant.Agrawal@fixcraft.in',
            phone: '7906348804',
        },
    },
    categories: {
        categories: [
            {
                id: 1,
                title: 'Brake System',
            },
            {
                id: 2,
                title: 'AC System',
            },
            {
                id: 3,
                title: 'Interior & Safety',
            },
            {
                id: 4,
                title: 'Electrical',
            },
            {
                id: 5,
                title: 'Engine',
            },
            {
                id: 6,
                title: 'Transmission & Clutch',
            },
            {
                id: 7,
                title: 'Lighting',
            },
            {
                id: 8,
                title: 'Suspension',
            },
            {
                id: 9,
                title: 'Body Parts',
            },
            {
                id: 10,
                title: 'Universal',
            },
            {
                id: 11,
                title: 'Fuel Supply System',
            },
            {
                id: 12,
                title: 'Exhaust',
            },
            {
                id: 13,
                title: 'Steering',
            },
            {
                id: 14,
                title: 'Wheel System',
            },
            {
                id: 15,
                title: 'Windscreen & Cleaning',
            },
        ],
    },
    subcategories: {
        part_subcategories: [
            {
                id: 1,
                title: 'AC Repair Kit',
                category_id: 2,
            },
            {
                id: 2,
                title: 'AC Walve',
                category_id: 2,
            },
            {
                id: 3,
                title: 'Brakes',
                category_id: 1,
            },
            {
                id: 4,
                title: 'Oil',
                category_id: 3,
            },
        ],
        subList: [
            {
                id: 1,
                title: 'AC Repair Kit',
                subcategory_id: 2,
            },
            {
                id: 2,
                title: 'AC Walve',
                subcategory_id: 2,
            },
            {
                id: 3,
                title: 'Brakes',
                subcategory_id: 1,
            },
            {
                id: 4,
                title: 'Oil',
                subcategory_id: 3,
            },
        ],
    },
    order: {
        parts: [
            {
                id: 71818,
                part_number: '72321M74L00',
                manufacturer: 'MARUTI',
                manufacturer_nature: 'OEM',
                family: 'FENDER LINING',
                title: 'FENDER LINING',
                category: 'BODY PARTS',
                description: 'Front Fender Lining, Right',
                mrp: 424,
                tax: 28,
                hsn: '87089900',
                quantity: 10,
                lot_size: 5,
                discount: 100,
                amount: 4140,
            },
        ],
        total_amount: 4140,
    },
    orders: {
        count: 10,
        results: [
            {
                id: 1285,
                created_at: '01 Jan 2021 00:00:00 GMT',
                status: 'Shipped',
                total: 576767,
                total_quantity_of_items: 10,
                lot_size: 5,
            },
            {
                id: 1243,
                created_at: '21 Apr 2022 00:00:00 GMT',
                status: 'Placed',
                total: 100,
                total_quantity_of_items: 1,
                lot_size: 0,
            },
            {
                id: 125,
                created_at: '01 Jan 2021 00:00:00 GMT',
                status: 'Shipped',
                total: 576767,
                total_quantity_of_items: 10,
                lot_size: 5,
            },
            {
                id: 124,
                created_at: '21 Apr 2022 00:00:00 GMT',
                status: 'Placed',
                total: 100,
                total_quantity_of_items: 1,
                lot_size: 0,
            },
            {
                id: 123,
                created_at: '01 Jan 2021 00:00:00 GMT',
                status: 'Shipped',
                total: 576767,
                total_quantity_of_items: 10,
                lot_size: 5,
            },
            {
                id: 143,
                created_at: '21 Apr 2022 00:00:00 GMT',
                status: 'Placed',
                total: 100,
                total_quantity_of_items: 1,
                lot_size: 0,
            },
            {
                id: 1285,
                created_at: '01 Jan 2021 00:00:00 GMT',
                status: 'Shipped',
                total: 576767,
                total_quantity_of_items: 10,
                lot_size: 5,
            },
            {
                id: 243,
                created_at: '21 Apr 2022 00:00:00 GMT',
                status: 'Placed',
                total: 100,
                total_quantity_of_items: 1,
                lot_size: 0,
            },
            {
                id: 1883,
                created_at: '21 Apr 2022 00:00:00 GMT',
                status: 'Placed',
                total: 100,
                total_quantity_of_items: 1,
                lot_size: 0,
            },
            {
                id: 1993,
                created_at: '21 Apr 2022 00:00:00 GMT',
                status: 'Placed',
                total: 100,
                total_quantity_of_items: 1,
                lot_size: 0,
            },
        ],
    },
    address: {
        first_name: 'Shubham',
        last_name: 'Kamboj',
        company: null,
        address_line_1: 'Near Shiv Mandir',
        address_line_2: 'ABC, Gurgaon',
        city: 'Gurgaon',
        state: 'Haryana',
        pincode: '132005',
        email: 'shubham.kamboj@fixcraft.in',
        phone: '7906348804',
    },
    addresses: {
        count: 2,
        results: [
            {
                id: 15,
                first_name: 'Shubham',
                last_name: 'Kamboj',
                company: null,
                latitude: 78.3,
                longitude: 77.3,
                address_line_1: 'Near Shiv Mandir',
                address_line_2: 'ABC, Gurgaon',
                city: 'Gurgaon',
                state: 'Haryana',
                pincode: '132005',
                email: 'shubham.kamboj@fixcraft.in',
                phone: '7906348804',
                default_for_billing: true,
                default_for_shipping: false,
            },
            {
                id: 16,
                first_name: 'Shubham',
                last_name: 'Kamboj 2',
                company: null,
                latitude: 10.3,
                longitude: 4.3,
                address_line_1: 'Near Fixcraft',
                address_line_2: 'ABC, Gurgaon ',
                city: 'Gurgaon',
                state: 'Haryana',
                pincode: '132005',
                email: 'sh@fixcraft.in',
                phone: '7906348804',
                default_for_billing: false,
                default_for_shipping: true,
            },
            {
                id: 17,
                first_name: 'Shubham',
                last_name: 'Kamboj 2',
                company: null,
                latitude: 10.3,
                longitude: 4.3,
                address_line_1: 'Near Fixcraft',
                address_line_2: 'ABC, Gurgaon ',
                city: 'Gurgaon',
                state: 'Haryana',
                pincode: '132005',
                email: 'sh@fixcraft.in',
                phone: '7906348804',
                default_for_billing: false,
                default_for_shipping: true,
            },
        ],
    },
    parts: {
        'brandix-spark-plug-kit-asr-400': {
            name: 'Brandix Spark Plug Kit ASR-400',
            sku_id: 123,
            part_number: '72321M74L00',
            manufacturer: 'V-Motive',
            title: 'FENDER LINING',
            category: '',
            family: 'FENDER LINING',
            description: 'FENDER LINING RIGHT',
            mrp: 1000,
            tax: 18,
            hsn: 28324843,
            lot_size: 5,
            images: ['image link 1', 'image link 2'],
            features: ['the fender lining', 'xyz'],
            specifications: {
                length: '150mm/6in',
                height: '142cm',
                weight: '800gm',
            },
        },
    },
    cars: {
        years: ['2018', '2019', '2020'],
        car_variants: [
            {
                id: 1,
                variant: '1.2L lxi',
            },
        ],
        car_models: [
            {
                id: 1,
                model: 'WAGONR',
            },
            {
                id: 2,
                model: 'SWIFT',
            },
        ],
        car_brands: [
            {
                id: 12,
                brand: 'Maruti',
            },
            {
                id: 13,
                brand: 'Honda',
            },
            {
                id: 14,
                brand: 'Hyundai',
            },
            {
                id: 15,
                brand: 'Mahindra',
            },
        ],
    },
    products: {
        count: 4,
        results: [
            {
                id: 1,
                sku_id: 123,
                name: 'Brakes',
                part_number: '72321M74L00',
                manufacturer: 'MARUTI',
                manufacturer_nature: 'OEM',
                family: 'FENDER LINING',
                subcategory: 'FENDER LINING',
                category: 'BODY PARTS',
                description: 'Front Fender Lining, Right',
                mrp: 42464,
                tax: 28,
                hsn: '87089900',
                lot_size: 5,
                rating: 4,
                image: [],
            },
            {
                id: 123,
                sku_id: 12,
                part_number: '72321M74L00',
                manufacturer: 'MARUTI',
                name: 'Brakes',
                manufacturer_nature: 'OEM',
                family: 'FENDER LINING',
                subcategory: 'FENDER LINING',
                category: 'BODY PARTS',
                description: 'Front Fender Lining, Right',
                mrp: 42464,
                tax: 28,
                hsn: '87089900',
                lot_size: 5,
                image: [],
            },
            {
                id: 12,
                sku_id: 1,
                part_number: '72321M74L00',
                manufacturer: 'MARUTI',
                name: 'Brakes',
                manufacturer_nature: 'OEM',
                family: 'FENDER LINING',
                subcategory: 'FENDER LINING',
                category: 'BODY PARTS',
                description: 'Front Fender Lining, Right',
                mrp: 4204,
                tax: 28,
                hsn: '87089900',
                lot_size: 5,
                image: [],
            },
            {
                id: 1234,
                sku_id: 1234,
                part_number: '72321M74L00',
                manufacturer: 'MARUTI',
                manufacturer_nature: 'OEM',
                name: 'Brakes',
                family: 'FENDER LINING',
                subcategory: 'FENDER LINING',
                category: 'BODY PARTS',
                description: 'Front Fender Lining, Right',
                mrp: 4240,
                tax: 28,
                hsn: '87089900',
                lot_size: 5,
                image: [],
            },
        ],
    },
};
