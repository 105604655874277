// application
import { cartAddItem, cartRemoveItem, cartUpdateQuantities, fetchOrder, resetCart } from '~/store/cart/cartActions';
import { useAppAction, useAppSelector } from '~/store/hooks';
import { CART_NAMESPACE } from './cartReducer';

export const useCart = () => useAppSelector((state) => state[CART_NAMESPACE]);

export const useCartItems = () => useAppSelector((state) => state[CART_NAMESPACE].order_items);

export const useCurrentCartItem = (sku_id: string) =>
    useAppSelector((state) => state[CART_NAMESPACE].order_items.find((each) => each.sku_id === sku_id));

export const useOrderId = () => useAppSelector((state) => state[CART_NAMESPACE].id);

export const useFetchOrder = () => useAppAction(fetchOrder);

export const useCartAddItem = () => useAppAction(cartAddItem);

export const useCartRemoveItem = () => useAppAction(cartRemoveItem);

export const useCartUpdateQuantities = () => useAppAction(cartUpdateQuantities);

export const useResetCart = () => useAppAction(resetCart);
