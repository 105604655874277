// react
import React, { useRef } from 'react';
// third-party
import classNames from 'classnames';
// application
import useVehicleForm from '~/services/forms/vehicle';
import { IVehicle } from '~/interfaces/vehicle';

type Location = 'search' | 'account' | 'modal';

interface Props {
    location: Location;
    onVehicleChange?: (event: IVehicle | null) => void;
}

function VehicleForm(props: Props) {
    const { location, onVehicleChange = () => {} } = props;
    const internalValue = useRef<IVehicle | null>(null);

    const updateValue = (vehicle: IVehicle | null) => {
        if (vehicle !== internalValue.current) {
            internalValue.current = vehicle;

            onVehicleChange(vehicle);
        }
    };

    const vehicleForm = useVehicleForm({
        onChange: (vehicle) => updateValue(vehicle),
    });

    const rootClasses = classNames('vehicle-form', `vehicle-form--layout--${location}`);

    return (
        <div className={rootClasses}>
            {vehicleForm.items.map((item, itemIdx) => {
                const options = item.options as Array<number | string | IVehicle>;

                return (
                    <div
                        key={itemIdx}
                        className={classNames('vehicle-form__item', 'vehicle-form__item--select', {
                            'vehicle-form__item--loading': item.loading,
                        })}
                    >
                        <select
                            className="form-control"
                            aria-label={item.label}
                            name={item.key}
                            value={item.value}
                            disabled={item.disabled}
                            onChange={(e) => vehicleForm.onItemValueChange(itemIdx, e.target.value)}
                        >
                            <option value="none">{item.placeholder}</option>
                            {options.map((option, optionIdx) => (
                                <option key={optionIdx} value={vehicleForm.serializeOption(option, item)}>
                                    {vehicleForm.serializeOption(option, item)}
                                </option>
                            ))}
                        </select>
                        <div className="vehicle-form__loader" />
                    </div>
                );
            })}
        </div>
    );
}

export default VehicleForm;
