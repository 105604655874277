import ArrowDown9x6 from './arrow-down-9x6.svg';
import ArrowDownSm7x5 from './arrow-down-sm-7x5.svg';
import ArrowRoundedDown9x6 from './arrow-rounded-down-9x6.svg';
import ArrowRoundedDown12x7 from './arrow-rounded-down-12x7.svg';
import ArrowRoundedLeft6x9 from './arrow-rounded-left-6x9.svg';
import ArrowRoundedLeft7x11 from './arrow-rounded-left-7x11.svg';
import ArrowRoundedRight6x9 from './arrow-rounded-right-6x9.svg';
import ArrowRoundedRight7x11 from './arrow-rounded-right-7x11.svg';
import Car20 from './car-20.svg';
import Cart20 from './cart-20.svg';
import Cart32 from './cart-32.svg';
import Check12x9 from './check-12x9.svg';
import Check100 from './check-100.svg';
import Compare16 from './compare-16.svg';
import Cross9Light from './cross-9-light.svg';
import Cross10 from './cross-10.svg';
import Cross12 from './cross-12.svg';
import Cross20 from './cross-20.svg';
import Fi24Hours48 from './fi-24-hours-48.svg';
import FiFreeDelivery48 from './fi-free-delivery-48.svg';
import Filters16 from './filters-16.svg';
import FiPaymentSecurity48 from './fi-payment-security-48.svg';
import Fit from './fit.svg';
import FiTag48 from './fi-tag-48.svg';
import Heart20 from './heart-20.svg';
import Heart32 from './heart-32.svg';
import LayoutGrid16 from './layout-grid-16.svg';
import LayoutGridWithDetails16 from './layout-grid-with-details-16.svg';
import LayoutList16 from './layout-list-16.svg';
import LayoutTable16 from './layout-table-16.svg';
import Menu16x12 from './menu-16x12.svg';
import Menu18x14 from './menu-18x14.svg';
import NoFit from './no-fit.svg';
import Person20 from './person-20.svg';
import Person32 from './person-32.svg';
import Quickview16 from './quickview-16.svg';
import RecycleBin16 from './recycle-bin-16.svg';
import Search20 from './search-20.svg';
import Wishlist16 from './wishlist-16.svg';
import ZoomIn24 from './zoom-in-24.svg';
import VMotiveLogo from './Vmotive_logo.svg';

export const ArrowDown9x6Svg = ArrowDown9x6;
export const ArrowDownSm7x5Svg = ArrowDownSm7x5;
export const ArrowRoundedDown9x6Svg = ArrowRoundedDown9x6;
export const ArrowRoundedDown12x7Svg = ArrowRoundedDown12x7;
export const ArrowRoundedLeft6x9Svg = ArrowRoundedLeft6x9;
export const ArrowRoundedLeft7x11Svg = ArrowRoundedLeft7x11;
export const ArrowRoundedRight6x9Svg = ArrowRoundedRight6x9;
export const ArrowRoundedRight7x11Svg = ArrowRoundedRight7x11;
export const Car20Svg = Car20;
export const Cart20Svg = Cart20;
export const Cart32Svg = Cart32;
export const Check12x9Svg = Check12x9;
export const Check100Svg = Check100;
export const Compare16Svg = Compare16;
export const Cross9LightSvg = Cross9Light;
export const Cross10Svg = Cross10;
export const Cross12Svg = Cross12;
export const Cross20Svg = Cross20;
export const Fi24Hours48Svg = Fi24Hours48;
export const FiFreeDelivery48Svg = FiFreeDelivery48;
export const Filters16Svg = Filters16;
export const FiPaymentSecurity48Svg = FiPaymentSecurity48;
export const FiTag48Svg = FiTag48;
export const FitSvg = Fit;
export const Heart20Svg = Heart20;
export const Heart32Svg = Heart32;
export const LayoutGrid16Svg = LayoutGrid16;
export const LayoutGridWithDetails16Svg = LayoutGridWithDetails16;
export const LayoutList16Svg = LayoutList16;
export const LayoutTable16Svg = LayoutTable16;
export const Menu16x12Svg = Menu16x12;
export const Menu18x14Svg = Menu18x14;
export const NoFitSvg = NoFit;
export const Person20Svg = Person20;
export const Person32Svg = Person32;
export const Quickview16Svg = Quickview16;
export const RecycleBin16Svg = RecycleBin16;
export const Search20Svg = Search20;
export const Wishlist16Svg = Wishlist16;
export const ZoomIn24Svg = ZoomIn24;
export const VMotiveLogoSvg = VMotiveLogo;
