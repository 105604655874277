// application
// @ts-nocheck
import http, { httpRequest } from '~/helpers/http';
import { IAddress } from '~/interfaces/address';
import { ISuccess } from '~/interfaces/user';
import { MOCKRESPONSE } from './mockResponse';

export function getAddress(addressId: number): Promise<IAddress> {
    return new Promise<IAddress>((resolve, reject) => {
        http.get(`addresses/${addressId}`, {}, { stubSuccess: MOCKRESPONSE.address })
            .then((response) => resolve(response))
            .catch((err) => reject(err));
    });
}

export function getAddresses(): Promise<IAddress[]> {
    return new Promise<IAddress[]>((resolve, reject) => {
        httpRequest({ method: 'get', url: 'business/address/', withAuthToken: true })
            .then((response) => resolve(response))
            .catch((err) => reject(err));
    });
}

export function addAddress(data: Partial<IAddress>): Promise<ISuccess> {
    const address: Partial<IAddress> = {
        city: '',
        state: '',
        address1: '',
        address2: '',
        pin_code: '',
        area: '',
        country: 'India',
        latitude: '',
        longitude: '',
        is_default: true,
        address_type: 'shipping',
        alias: '',
        default_for_billing: true,
        default_for_shipping: true,
        ...data,
    };

    return new Promise<ISuccess>((resolve, reject) => {
        httpRequest({ method: 'post', url: 'business/address/', data: { ...address }, withAuthToken: true })
            .then((response) => resolve(response))
            .catch((err) => reject(err));
    });
}

export function editAddress(addressId: number, data: Partial<IAddress>): Promise<ISuccess> {
    if (!addressId) {
        throw new Error('Address not found');
    }
    return new Promise<ISuccess>((resolve, reject) => {
        httpRequest({
            method: 'patch',
            url: 'business/address/update-address/',
            data: { ...data },
            withAuthToken: true,
        })
            .then((response) => resolve(response))
            .catch((err) => reject(err));
    });
}

export function deleteAddress(addressId: number): Promise<ISuccess> {
    return new Promise<ISuccess>((resolve, reject) => {
        http.delete(`addresses/${addressId}`, {}, { stubSuccess: MOCKRESPONSE.success })
            .then((response) => resolve(response))
            .catch((err) => reject(err));
    });
}
