/* eslint-disable import/prefer-default-export */
export const formatReviews = (rating: number) => {
    if (rating === 1) {
        return `${rating} review`;
    }
    if (rating === 0) {
        return 'No reviews';
    }
    return `${rating} reviews`;
};

export const formatRating = (rating: number) => {
    if (rating === 1) {
        return `${rating} star rating`;
    }
    if (rating === 0) {
        return 'No rating';
    }
    return `${rating} stars rating`;
};

export const formatProduct = (count: number) => {
    if (count === 1) {
        return `${count} Product`;
    }
    if (count === 0) {
        return 'No products';
    }
    return `${count} Products`;
};

export const formatDate = (date: string) => new Date(date).toLocaleDateString('en-US', { dateStyle: 'medium' });
