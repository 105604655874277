// application
import { IMobileMenuState } from '~/store/mobile-menu/mobileMenuTypes';
import {
    MOBILE_MENU_CLOSE,
    MOBILE_MENU_OPEN,
    MobileMenuAction,
    FETCH_MASTER_DATA_SUCCESS,
} from '~/store/mobile-menu/mobileMenuActionTypes';

const initialState: IMobileMenuState = {
    open: false,
    isLoading: true,
    car_brands: [],
    car_models: {},
    car_generations: [],
    car_variants: [],
    part_categories: [],
    part_subcategories: [],
    part_families: [],
    part_manufacturers: [],
    car_manufacture_years: [],
    v_motive_subcategories: [],
};

export const MOBILE_MENU_NAMESPACE = 'mobileMenu';

export default function mobileMenuReducer(state = initialState, action: MobileMenuAction): IMobileMenuState {
    switch (action.type) {
        case MOBILE_MENU_OPEN:
            return {
                ...state,
                open: true,
            };
        case MOBILE_MENU_CLOSE:
            return {
                ...state,
                open: false,
            };
        case FETCH_MASTER_DATA_SUCCESS:
            return {
                ...state,
                isLoading: false,
                ...action.categories,
            };
        default:
            return state;
    }
}
