// application
import { MOBILE_MENU_NAMESPACE } from '~/store/mobile-menu/mobileMenuReducer';
import { fetchMasterData, mobileMenuClose, mobileMenuOpen } from '~/store/mobile-menu/mobileMenuActions';
import { useAppAction, useAppSelector } from '~/store/hooks';

export const useMobileMenu = () => useAppSelector((state) => state[MOBILE_MENU_NAMESPACE]);

export const useMenuItemIsLoading = () => useAppSelector((state) => state[MOBILE_MENU_NAMESPACE].isLoading);

export const usePartCategories = () => useAppSelector((state) => state[MOBILE_MENU_NAMESPACE].part_categories);

export const usePartSubcategories = () => useAppSelector((state) => state[MOBILE_MENU_NAMESPACE].part_subcategories);

export const useCarBrands = () => useAppSelector((state) => state[MOBILE_MENU_NAMESPACE].car_brands);

export const useCarModels = () => useAppSelector((state) => state[MOBILE_MENU_NAMESPACE].car_models);

export const useCarVariants = () => useAppSelector((state) => state[MOBILE_MENU_NAMESPACE].car_variants);

export const useCarGenerations = () => useAppSelector((state) => state[MOBILE_MENU_NAMESPACE].car_generations);

export const useCarManufacturingYears = () => useAppSelector((state) => state[MOBILE_MENU_NAMESPACE].car_manufacture_years);

export const useMobileMenuOpen = () => useAppAction(mobileMenuOpen);

export const useMobileMenuClose = () => useAppAction(mobileMenuClose);

export const useFetchMasterData = () => useAppAction(fetchMasterData);
