// application
// import { getPopularCategories } from '~/api';
import { getMasterData } from '~/api';
import {
    MOBILE_MENU_CLOSE,
    MOBILE_MENU_OPEN,
    MobileMenuCloseAction,
    MobileMenuOpenAction,
    FetchMasterDataSuccessAction,
    FETCH_MASTER_DATA_SUCCESS,
    MobileMenuThunkAction,
} from '~/store/mobile-menu/mobileMenuActionTypes';
import { ICarBrand, ICarModel, IPartSubCategory } from './mobileMenuTypes';

export function mobileMenuOpen(): MobileMenuOpenAction {
    return { type: MOBILE_MENU_OPEN };
}

export function mobileMenuClose(): MobileMenuCloseAction {
    return { type: MOBILE_MENU_CLOSE };
}

export function fetchMasterDataSuccess(categories: any): FetchMasterDataSuccessAction {
    return {
        type: FETCH_MASTER_DATA_SUCCESS,
        categories,
    };
}
// TODO:- remove when we haveproduct in other categories
const CATEGORIES_WITH_PRODUCT = ['BRAKE SYSTEM', 'FILTERS', 'OILS & FLUIDS', 'WINDSCREEN CLEANING SYSTEM'];
const SUBCATEGORIES_WITH_PRODUCT = [
    'BRAKE PAD',
    'OIL FILTER',
    'AIR FILTER',
    'CABIN FILTER',
    'FUEL FILTER',
    'BRAKE FLUID',
    'CLEANING AGENTS',
    'COOLANT',
    'ENGINE OIL',
    'TRANSMISSION OIL',
    'WASHER COMPONENTS',
    'WIPER COMPONENTS',
];

export function fetchMasterData(): MobileMenuThunkAction<Promise<void>> {
    return (dispatch) =>
        getMasterData().then((data) => {
            // @ts-ignore
            const categories = (data?.part_categories || []).filter((each) =>
                CATEGORIES_WITH_PRODUCT.includes(each.category)
            );
            // @ts-ignore
            const subcategories = (data?.part_subcategories || []).filter((each: IPartSubCategory) =>
                SUBCATEGORIES_WITH_PRODUCT.includes(each.subcategory)
            );
            const car_models: { [key: number]: ICarModel[] } = {};
            // @ts-ignore
            (data?.car_models ?? [])?.forEach((element: ICarModel) => {
                if (!car_models[element.brand_id]) {
                    car_models[element.brand_id] = [];
                }
                car_models[element.brand_id].push(element);
            });
            // @ts-ignore
            (data?.car_brands ?? [])?.forEach((element: ICarBrand) => {
                if (!car_models[element.id]) {
                    car_models[element.id] = [];
                }
            });

            dispatch(
                fetchMasterDataSuccess({
                    // @ts-ignore
                    ...data,
                    part_categories: categories,
                    part_subcategories: subcategories,
                    car_models,
                })
            );
        });
}
