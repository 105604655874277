// application
// @ts-nocheck
import { IListOptions, IOrdersList } from '~/interfaces/list';
import { IOrder, IOrderDetails, IOrderItem, IOrderResponse, IOrderSumary } from '~/interfaces/order';
import http, { httpRequest } from '~/helpers/http';
import { MOCKRESPONSE } from './mockResponse';
import { makePageBasedNavigation } from '~/helpers/utils';
import { ICheckoutData } from './products';

export function getActiveOrder(): Promise<any> {
    return new Promise((resolve, reject) => {
        httpRequest({
            url: 'business/order/get-active-order/',
            queryParams: { order_type: 'V-Motive' },
            method: 'get',
            withAuthToken: true,
        })
            .then((response: IOrderDetails) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
}
export function createOrder(): Promise<any> {
    return new Promise((resolve, reject) => {
        httpRequest({
            url: 'business/order/',
            data: { order_type: 'V-Motive', order_items: [] },
            withAuthToken: true,
            method: 'post',
        })
            .then((response: IOrderResponse) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function addOrderItem(data): Promise<IOrderDetails> {
    return new Promise((resolve, reject) => {
        httpRequest({
            url: 'business/order-item/',
            data,
            withAuthToken: true,
            method: 'post',
        })
            .then((response: IOrderDetails) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function updateOrderItem(orderItemId: number, quantity: number): Promise<IOrderItem> {
    return new Promise((resolve, reject) => {
        httpRequest({
            url: `business/order-item/${orderItemId}/`,
            data: { quantity },
            withAuthToken: true,
            method: 'patch',
        })
            .then((response: IOrderResponse) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function deleteOrderItem(orderItemId: number): Promise<IOrderItem> {
    return new Promise((resolve, reject) => {
        httpRequest({
            url: `business/order-item/${orderItemId}/`,
            withAuthToken: true,
            method: 'delete',
        })
            .then((response: IOrderResponse) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function getOrdersList(options?: IListOptions): Promise<IOrdersList> {
    return new Promise<IOrdersList>((resolve, reject) => {
        httpRequest({
            url: 'business/order/summary/',
            queryParams: { summary_type: 'active', page: 1 },
            withAuthToken: true,
            method: 'get',
        })
            .then((response: IOrderResponse) => {
                const limit = 10;
                let items: IOrderSumary[] = response.results.filter((each) => !each.customer_uploaded_file);

                const [chunk, navigation] = makePageBasedNavigation(
                    items,
                    limit,
                    options?.page || 1,
                    response?.count || 0
                );
                items = chunk;

                resolve({
                    items,
                    navigation,
                });
                return null;
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function getOrderById(id: number): Promise<IOrderDetails> {
    return new Promise<IOrder>((resolve, reject) => {
        httpRequest({
            url: `business/order/${id}/order-tracking/`,
            method: 'get',
            withAuthToken: true,
        })
            .then((response: IOrder) => {
                resolve(response);
            })
            .catch(() => {
                reject();
            });
    });
}

export function checkout(data: ICheckoutData): Promise<IOrder> {
    return new Promise<IOrder>((resolve, reject) => {
        httpRequest({
            url: `business/order/${data.id}/place-order/`,
            method: 'patch',
            data: { shipping_address_id: data.shipping_address_id },
            withAuthToken: true,
        })
            .then((response: IOrder) => {
                resolve(response);
            })
            .catch(() => {
                reject();
            });
    });
}
