import { captureException } from '@sentry/nextjs';
import axios, { AxiosError } from 'axios';
import queryStringBuilder from './queryStringBuilder';
import { getAuthToken } from './utils';

export const NOT_FOUND = 404;
export const BAD_REQUEST = 400;
export const NO_CONTENT = 204;

// To have an prefix added to your API calls uncomment the line below
// and set the prefix according you your needs. The setting below
// will result in /api/your-endpoint-name being used to call the api
// not we should move this to a config file and doucment it in the readme

const ASYNC_DELAY = 900;
export type MockResults = { stubSuccess?: any; stubError?: any };

function buildUrl(url: string) {
    return `${process.env.NEXT_PUBLIC_URL}/${url}`;
}

function addJwtToken(config: { headers: any }) {
    const jwtToken = '';
    if (!jwtToken || !config) {
        return config;
    }

    const authorization = `Bearer ${jwtToken}`;

    return {
        ...config,
        headers: {
            ...config.headers,
            Authorization: authorization,
        },
    };
}

// const parseJwtTokenFromHeader = (authorizationHeader: string | null): any => {
//     if (!authorizationHeader) {
//         return null;
//     }
//     const tokens = authorizationHeader.match(/\S+/g) ?? [];

//     // We are getting the second token because the first token will be Bearer.
//     // EX: Bearer woeirweoirjw....
//     return tokens.length > 1 ? tokens[1] : null;
// };

// function setJwtTokenFromHeaderResponse(authorizationHeader: string | null) {
//     const jwtToken = parseJwtTokenFromHeader(authorizationHeader);
//     if (jwtToken) {
//         localStorage.setItem('jwtToken', jwtToken);
//     } else {
//         localStorage.removeItem('jwtToken');
//     }
// }

// If stubSuccess or stubError is defined then we will fake a successful call to the
// server and return stubSuccess as the response. This allows for easily
// faking calls during development when APIs aren't ready. A warning
// will be written out for each stubbed response to help prevent forgetting
// about the stubs.
function doFetch(url: string, config: any, { stubSuccess, stubError }: MockResults = {}) {
    if (!url) {
        throw new Error('You must specify a url');
    }
    // if (process.env.NODE_ENV === 'development') {
    if (stubSuccess) {
        return new Promise((resolve) =>
            setTimeout(() => {
                // eslint-disable-next-line no-console
                console.warn(`Stubbed service call made to url: ${url}`);
                resolve(stubSuccess);
            }, ASYNC_DELAY)
        );
    }

    if (stubError) {
        return new Promise((resolve, reject) =>
            setTimeout(() => {
                // eslint-disable-next-line no-console
                console.warn(`Stubbed service error was returned from url: ${url}`);
                reject(stubError);
            }, ASYNC_DELAY)
        );
    }
    // }

    return (
        fetch(buildUrl(url), addJwtToken(config))
            .then((response: Response) => {
                if (response.headers) {
                    // const authHeader = response.headers.get('Authorization');
                    // setJwtTokenFromHeaderResponse(authHeader);
                    // updateSessionToken(parseJwtTokenFromHeader(authHeader));
                }

                if (response.ok) {
                    if (response.status === NO_CONTENT) {
                        return Promise.resolve('No Content');
                    }
                    return response.json();
                }

                const unauthorized = 401;
                if (response.status === unauthorized) {
                    // All else failed so redirect user ot FMS to reauthenticate
                    // localStorage.removeItem('jwtToken');
                }

                return Promise.reject(response);
            })
            // eslint-disable-next-line consistent-return
            .catch((err) => Promise.reject(err))
    );
}
export function httpRequest<T>({
    method = 'get',
    url = '',
    queryParams = {},
    data = {},
    withAuthToken = false,
    options = {},
}: {
    method: 'get' | 'post' | 'delete' | 'patch';
    url: string;
    queryParams?: any;
    data?: any;
    withAuthToken?: any;
    options?: any;
}): Promise<T> {
    const queryString = queryStringBuilder(queryParams);
    const combinedUrl = `${buildUrl(url)}${queryString}`;

    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
        if (withAuthToken) {
            const userToken = await getAuthToken();
            // eslint-disable-next-line no-param-reassign
            options = {
                headers: {
                    Authorization: `Token ${userToken}`,
                },
            };
        }

        axios[method](combinedUrl, method === 'get' || method === 'delete' ? options : data, options)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error: AxiosError) => {
                captureException(error);
                if (error.response && error.response.status === 403) {
                    // eslint-disable-next-line prefer-promise-reject-errors
                    reject({
                        message: 'Unauthorized',
                        error: error.response,
                    });
                } else if (error.response && error.response.status === 400) {
                    // eslint-disable-next-line prefer-promise-reject-errors
                    reject({
                        message: 'Missing data, Please make sure all values are filled correctly.',
                        error: error.response,
                    });
                } else if (error.response && (error.response.status === 500 || error.response.status === 502)) {
                    // eslint-disable-next-line prefer-promise-reject-errors
                    reject({
                        message: 'Something went wrong with our servers, Please try after sometime.',
                        error: error.response,
                    });
                } else {
                    // eslint-disable-next-line prefer-promise-reject-errors
                    reject({
                        message: 'Something went wrong with our servers, Please try after sometime.',
                    });
                }
            });
    });
}

// eslint-disable-next-line max-len
const get = (url: string, config: any = {}, { stubSuccess, stubError }: MockResults = {}) =>
    doFetch(url, config, { stubSuccess, stubError });

const post = (url: string, config: any, { stubSuccess, stubError }: MockResults = {}) =>
    doFetch(
        url,
        {
            ...config,
            method: 'POST',
        },
        { stubSuccess, stubError }
    );

const put = (url: string, config: any, { stubSuccess, stubError }: MockResults = {}) =>
    doFetch(
        url,
        {
            ...config,
            method: 'PUT',
        },
        { stubSuccess, stubError }
    );

const patch = (url: string, config: any, { stubSuccess, stubError }: MockResults = {}) =>
    doFetch(
        url,
        {
            ...config,
            method: 'PATCH',
        },
        { stubSuccess, stubError }
    );

const callDelete = (url: string, config: any, { stubSuccess, stubError }: MockResults = {}) =>
    doFetch(
        url,
        {
            ...config,
            method: 'POST',
        },
        { stubSuccess, stubError }
    );

export default {
    get,
    post,
    put,
    patch,
    delete: callDelete,
};
