// application
import { AppAction } from '~/store/types';
import { IUser } from '~/interfaces/user';
import { IAddress } from '~/interfaces/address';

export const USER_SET_CURRENT = 'USER_SET_CURRENT';
export const USER_SET_TOKEN = 'USER_SET_TOKEN';
export const USER_SET_ADDRESS = 'USER_SET_ADDRESS';

export interface UserSetCurrentAction {
    type: typeof USER_SET_CURRENT;
    payload: IUser | null;
}
export interface UserSetTokenAction {
    type: typeof USER_SET_TOKEN;
    payload: string | null;
}
export interface UserSetAddressAction {
    type: typeof USER_SET_ADDRESS;
    payload: IAddress | null;
}

export type UserAction = UserSetCurrentAction | UserSetTokenAction | UserSetAddressAction;

export type UserThunkAction<T = void> = AppAction<UserAction, T>;
