// react
import React, { useMemo, useRef } from 'react';
// application
import AccountMenu from '~/components/header/AccountMenu';
import CurrencyFormat from '~/components/shared/CurrencyFormat';
import Departments from '~/components/header/Departments';
import Dropcart from '~/components/header/Dropcart';
import Indicator, { IIndicatorController } from '~/components/header/Indicator';
import Logo from '~/components/header/Logo';
import Search from '~/components/header/Search';
import url from '~/services/url';
import { Person32Svg, Cart32Svg } from '~/svg';
import { useCart } from '~/store/cart/cartHooks';
import { useOptions } from '~/store/options/optionsHooks';
import { useUser } from '~/store/user/userHooks';

function Header() {
    const user = useUser();
    const options = useOptions();
    const desktopLayout = options.desktopHeaderLayout;

    const departmentsLabel = useMemo(
        () => (desktopLayout === 'spaceship' ? 'Menu' : 'Shop By Category'),
        [desktopLayout]
    );

    const accountIndicatorCtrl = useRef<IIndicatorController | null>(null);

    const cart = useCart();
    const cartIndicatorCtrl = useRef<IIndicatorController | null>(null);

    return (
        <div className="header">
            <div className="header__megamenu-area megamenu-area" />
            <div className="header__topbar-start-bg" />
            <div className="header__topbar-end-bg" />

            <div className="header__navbar">
                <div className="header__navbar-departments">
                    <Departments label={departmentsLabel} />
                </div>
            </div>
            <Logo className="header__logo" />
            <div className="header__search">
                <Search />
            </div>
            <div className="header__indicators">
                <Indicator
                    href={url.accountDashboard()}
                    icon={<Person32Svg />}
                    label={user ? user.email : 'Hello, Log In'}
                    value="My Account"
                    trigger="click"
                    controllerRef={accountIndicatorCtrl}
                >
                    <AccountMenu onCloseMenu={() => accountIndicatorCtrl.current?.close()} />
                </Indicator>

                <Indicator
                    href={url.cart()}
                    icon={<Cart32Svg />}
                    label="Shopping Cart"
                    value={cart.total_amount > 0 ? <CurrencyFormat value={cart.total_amount} /> : null}
                    counter={cart.order_items.length}
                    trigger="click"
                    controllerRef={cartIndicatorCtrl}
                >
                    <Dropcart onCloseMenu={() => cartIndicatorCtrl.current?.close()} />
                </Indicator>
            </div>
        </div>
    );
}

export default React.memo(Header);
