// react
import React from 'react';
// third-party
import classNames from 'classnames';
// application
// import AppImage from '~/components/shared/AppImage';
import MegamenuLinks from '~/components/header/MegamenuLinks';
import { IMegamenu } from '~/interfaces/menu';
import { IPartCategory, IPartSubCategory } from '~/store/mobile-menu/mobileMenuTypes';

interface Props extends React.HTMLAttributes<HTMLElement> {
    menu: IPartSubCategory[] | IMegamenu;
    category: IPartCategory;
    onItemClick?: () => void;
}

function Megamenu(props: Props) {
    const { menu, onItemClick, className, category, ...rootProps } = props;

    const rootClasses = classNames('megamenu', className);

    return (
        <div className={rootClasses} {...rootProps}>
            {/* {hasImage && (
                <div className="megamenu__image">
                    <AppImage className="reflect-rtl" src={menu.image} />
                </div>
            )} */}
            <div className="row">
                <div className="col-sm">
                    <MegamenuLinks
                        className="megamenu__links"
                        category={category}
                        menu={menu}
                        onItemClick={onItemClick}
                    />
                </div>
            </div>
        </div>
    );
}

export default Megamenu;
