// react
import React, { useRef, useState } from 'react';
import classNames from 'classnames';
// application
import AppImage from '~/components/shared/AppImage';
import AppLink from '~/components/shared/AppLink';
import AsyncAction from '~/components/shared/AsyncAction';
import CurrencyFormat from '~/components/shared/CurrencyFormat';
import RadioButton from '~/components/shared/RadioButton';
import Rating from '~/components/shared/Rating';
import url from '~/services/url';
import VehicleForm from '~/components/shared/VehicleForm';
import { Car20Svg, RecycleBin16Svg, Search20Svg } from '~/svg';
import { IProduct } from '~/interfaces/product';
import { ICategoryV2 } from '~/interfaces/category';
import { IVehicle } from '~/interfaces/vehicle';
import { useGlobalMousedown } from '~/services/hooks';
import {
    useGarageAddItem,
    useGarageCurrent,
    useGarageRemoveItem,
    useGarageSetCurrent,
    useUserVehicles,
} from '~/store/garage/garageHooks';
import { formatReviews } from '~/helpers/formatter';
import { isVechileEquals } from '~/store/garage/garageReducer';
import { useRouter } from 'next/router';
import { hrefToRouterArgs } from '~/services/router';
import { setCookieItem } from '~/helpers/utils';

export function Search() {
    const router = useRouter();
    const [query, setQuery] = useState('');
    const [suggestionsIsOpen, setSuggestionsIsOpen] = useState(false);
    const [hasSuggestions, setHasSuggestions] = useState(false);
    const [products] = useState<IProduct[]>([]);
    // const [categories] = useState<ICategoryV2>([]);
    const [vehiclePickerIsOpen, setVehiclePickerIsOpen] = useState(false);
    const [vehiclePanel, setVehiclePanel] = useState('list');
    const [addVehicle, setAddVehicle] = useState<IVehicle | null>(null);
    const vehicles = useUserVehicles();
    const garageAddItem = useGarageAddItem();
    const garageRemoveItem = useGarageRemoveItem();
    const hasVehicles = vehicles.length > 0;
    const selectVehicleButtonRef = useRef<HTMLButtonElement>(null);
    const vehiclePickerDropdownRef = useRef<HTMLDivElement>(null);

    const currentVehicle = useGarageCurrent();
    const garageSetCurrent = useGarageSetCurrent();

    const searchCancelFnRef = useRef(() => {});
    const rootRef = useRef<HTMLDivElement>(null);

    const search = (value: string) => {
        searchCancelFnRef.current();

        // let canceled = false;

        searchCancelFnRef.current = () => {
            // canceled = true;
        };

        // getSearchSuggestions(value, {
        //     limitProducts: 3,
        //     limitCategories: 4,
        // }).then((result) => {
        //     if (canceled) {
        //         return;
        //     }

        //     if (result.products.length === 0 && result.categories.length === 0) {
        //         setHasSuggestions(false);
        //         return;
        //     }

        //     setHasSuggestions(true);
        //     setProducts(result.products);
        //     setCategories(result.categories);
        // });

        setQuery(value);
    };

    const toggleSuggestions = (force?: boolean) => {
        setSuggestionsIsOpen((prevState) => {
            const newState = force !== undefined ? force : !prevState;

            if (newState) {
                setVehiclePickerIsOpen(false);
            }

            return newState;
        });
    };

    const toggleVehiclePicker = (force?: boolean): void => {
        setVehiclePickerIsOpen((prevState) => {
            const newState = force !== undefined ? force : !prevState;

            if (newState) {
                setSuggestionsIsOpen(false);
            }

            return newState;
        });
    };

    const handleInputFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        const input = event.currentTarget;

        toggleSuggestions(true);
        search(input.value);
    };

    const handleInputChange = (event: React.FormEvent<HTMLInputElement>) => {
        const input = event.currentTarget;

        search(input.value);
    };

    const handleButtonClick = () => {
        toggleVehiclePicker();
    };

    const handleChangeCurrentVehicle = (vehicle: IVehicle | null) => {
        garageSetCurrent(vehicle);
    };

    const handleVehicleChange = (vehicle: IVehicle | null) => {
        setAddVehicle(vehicle);
        setVehiclePanel('list')
    };

    const handleRootBlur = () => {
        setTimeout(() => {
            if (document.activeElement === document.body) {
                return;
            }

            // Close suggestions if the focus received an external element.
            if (document.activeElement && document.activeElement.closest('.search') !== rootRef.current) {
                toggleSuggestions(false);
            }
        }, 10);
    };

    useGlobalMousedown(
        (event) => {
            const outsideButton =
                selectVehicleButtonRef.current && !selectVehicleButtonRef.current.contains(event.target as HTMLElement);
            const outsideDropdown =
                vehiclePickerDropdownRef.current &&
                !vehiclePickerDropdownRef.current.contains(event.target as HTMLElement);

            if (outsideButton && outsideDropdown) {
                setVehiclePickerIsOpen(false);
            }
        },
        [setVehiclePickerIsOpen, selectVehicleButtonRef]
    );

    useGlobalMousedown(
        (event) => {
            const outside = rootRef.current && !rootRef.current.contains(event.target as HTMLElement);

            if (outside && suggestionsIsOpen) {
                setHasSuggestions(false);
            }
        },
        [rootRef, suggestionsIsOpen, setHasSuggestions]
    );

    const searchPlaceholder = currentVehicle
        ? `Search for ${currentVehicle.year} ${currentVehicle.brand} ${currentVehicle.model}`
        : 'Enter Keyword or Part Number';

    const onSubmit = (event: React.FormEvent) => {
        event.preventDefault();

        router
            .push(
                ...hrefToRouterArgs(
                    url.products({
                        filters: {
                            search: query,
                            ...currentVehicle,
                        },
                    })
                )
            )
            .then(() => setQuery(''));
    };

    const handleAddVehicle = () => {
        const isVehicleExist = vehicles.some((item) => addVehicle && isVechileEquals(item, addVehicle));
        if (addVehicle && !isVehicleExist) {
            setCookieItem('garage', JSON.stringify([...vehicles, addVehicle]));
            handleVehicleChange(null)
            return garageAddItem(addVehicle);
        }

        return Promise.resolve();
    };

    return (
        <div className="search" ref={rootRef} onBlur={handleRootBlur}>
            <form className="search__body" onSubmit={onSubmit}>
                <div className="search__shadow" />

                <label className="sr-only" htmlFor="site-search">
                    Search for
                </label>

                <input
                    type="text"
                    className="search__input"
                    id="site-search"
                    autoCapitalize="off"
                    autoComplete="off"
                    spellCheck="false"
                    name="search"
                    value={query}
                    placeholder={searchPlaceholder}
                    onFocus={handleInputFocus}
                    onChange={handleInputChange}
                />

                <button
                    type="button"
                    className={classNames('search__button search__button--start', {
                        'search__button--hover': vehiclePickerIsOpen,
                    })}
                    onClick={handleButtonClick}
                    ref={selectVehicleButtonRef}
                >
                    <span className="search__button-icon">
                        <Car20Svg />
                    </span>
                    <span className="search__button-title">Select Vehicle</span>
                </button>

                <button className="search__button search__button--end" type="submit">
                    <span className="search__button-icon">
                        <Search20Svg />
                    </span>
                </button>

                <div className="search__box" />
                <div className="search__decor">
                    <div className="search__decor-start" />
                    <div className="search__decor-end" />
                </div>

                <div
                    className={classNames('search__dropdown', 'search__dropdown--suggestions', 'suggestions', {
                        'search__dropdown--open': suggestionsIsOpen && hasSuggestions,
                    })}
                >
                    {products.length > 0 && (
                        <div className="suggestions__group">
                            <div className="suggestions__group-title">Products</div>
                            <div className="suggestions__group-content">
                                {products.map((product) => (
                                    <AppLink
                                        key={product.id}
                                        href={url.product(product.sku_id)}
                                        className="suggestions__item suggestions__product"
                                        onClick={() => toggleSuggestions(false)}
                                    >
                                        <div className="suggestions__product-image">
                                            <AppImage src={product.image} />
                                        </div>
                                        <div className="suggestions__product-info">
                                            <div className="suggestions__product-name">{product.part_description}</div>
                                            <div className="suggestions__product-rating">
                                                <div className="suggestions__product-rating-stars">
                                                    <Rating value={product.id % 3} />
                                                </div>
                                                <div className="suggestions__product-rating-label">
                                                    {product.rating ? formatReviews(product.rating) : ''}
                                                </div>
                                            </div>
                                        </div>
                                        <div className=" suggestions__product-price">
                                            <CurrencyFormat value={product.mrp} />
                                        </div>
                                    </AppLink>
                                ))}
                            </div>
                        </div>
                    )}
                    {/* {categories.length > 0 && (
                        <div className="suggestions__group">
                            <div className="suggestions__group-title">Categories</div>
                            <div className="suggestions__group-content">
                                {categories.map((category) => (
                                    <AppLink
                                        key={category.id}
                                        href={url.category(category)}
                                        className="suggestions__item suggestions__category"
                                        onClick={() => toggleSuggestions(false)}
                                    >
                                        {category.title}
                                    </AppLink>
                                ))}
                            </div>
                        </div>
                    )} */}
                </div>

                <div
                    className={classNames('search__dropdown', 'search__dropdown--vehicle-picker', 'vehicle-picker', {
                        'search__dropdown--open': vehiclePickerIsOpen,
                    })}
                    ref={vehiclePickerDropdownRef}
                >
                    <div className="search__dropdown-arrow" />

                    <div
                        className={classNames('vehicle-picker__panel', {
                            'vehicle-picker__panel--active': vehiclePanel === 'list' && hasVehicles,
                        })}
                    >
                        <div className="vehicle-picker__panel-body">
                            <div className="vehicle-picker__text">Select a vehicle to find exact fit parts.</div>

                            <div className="vehicles-list">
                                <div className="vehicles-list__body">
                                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                                    <label className="vehicles-list__item">
                                        <RadioButton
                                            className="vehicles-list__item-radio"
                                            name="header-current-vehicle"
                                            defaultValue=""
                                            checked={currentVehicle === null}
                                            onChange={() => handleChangeCurrentVehicle(null)}
                                        />
                                        <span className=" vehicles-list__item-info">
                                            <span className=" vehicles-list__item-name">All Vehicles</span>
                                        </span>
                                    </label>
                                    {vehicles.map((vehicle, index) => (
                                        <React.Fragment key={index}>
                                            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                                            <label className="vehicles-list__item">
                                                <RadioButton
                                                    className="vehicles-list__item-radio"
                                                    name="header-current-vehicle"
                                                    checked={
                                                        !!currentVehicle && isVechileEquals(vehicle, currentVehicle)
                                                    }
                                                    onChange={() => handleChangeCurrentVehicle(vehicle)}
                                                />
                                                <span className="vehicles-list__item-info">
                                                    <span className="vehicles-list__item-name">
                                                        {`${vehicle.year} ${vehicle.brand} ${vehicle.model}`}
                                                    </span>
                                                    <span className="vehicles-list__item-details">
                                                        {`Variant ${vehicle.variant}`}
                                                    </span>
                                                </span>
                                                <AsyncAction
                                                    action={() => garageRemoveItem(vehicle, vehicles)}
                                                    render={({ run, loading }) => (
                                                        <button
                                                            type="button"
                                                            className={classNames('vehicles-list__item-remove', {
                                                                'vehicles-list__item-remove--loading': loading,
                                                            })}
                                                            onClick={run}
                                                        >
                                                            <RecycleBin16Svg />
                                                        </button>
                                                    )}
                                                />
                                            </label>
                                        </React.Fragment>
                                    ))}
                                </div>
                            </div>

                            <div className="vehicle-picker__actions">
                                <button
                                    type="button"
                                    className="btn btn-primary btn-sm"
                                    onClick={() => setVehiclePanel('form')}
                                >
                                    Add Vehicle
                                </button>
                            </div>
                        </div>
                    </div>

                    <div
                        className={classNames('vehicle-picker__panel', {
                            'vehicle-picker__panel--active': vehiclePanel === 'form' || !hasVehicles,
                        })}
                    >
                        <div className="vehicle-picker__panel-body">
                            <VehicleForm location="search" onVehicleChange={handleVehicleChange} />
                            <div className="vehicle-picker__actions">
                                {hasVehicles && (
                                    <div className="search__car-selector-link">
                                        {/* eslint-disable-next-line */}
                                        <AppLink
                                            anchor
                                            onClick={(event) => {
                                                event.preventDefault();

                                                setVehiclePanel('list');
                                            }}
                                        >
                                            Back to list
                                        </AppLink>
                                    </div>
                                )}

                                <AsyncAction
                                    action={handleAddVehicle}
                                    render={({ run, loading }) => (
                                        <button
                                            type="button"
                                            className={classNames('btn', 'btn-primary', 'btn-sm', {
                                                'btn-loading': loading,
                                            })}
                                            disabled={addVehicle === null}
                                            onClick={run}
                                        >
                                            Add Vehicle
                                        </button>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default Search;
