import { ICategoryV2 } from '~/interfaces/category';
import { AppAction } from '../types';

export const MOBILE_MENU_OPEN = 'MOBILE_MENU_OPEN';
export const MOBILE_MENU_CLOSE = 'MOBILE_MENU_CLOSE';
export const FETCH_MASTER_DATA_SUCCESS = 'FETCH_MASTER_DATA_SUCCESS';

export interface MobileMenuOpenAction {
    type: typeof MOBILE_MENU_OPEN;
}

export interface MobileMenuCloseAction {
    type: typeof MOBILE_MENU_CLOSE;
}

export interface FetchMasterDataSuccessAction {
    type: typeof FETCH_MASTER_DATA_SUCCESS;
    categories: any;
}

export type MobileMenuAction = MobileMenuOpenAction | MobileMenuCloseAction | FetchMasterDataSuccessAction;

export type MobileMenuThunkAction<T = void> = AppAction<MobileMenuAction, T>;
