/* eslint-disable import/prefer-default-export */

export function validateEmail(value: string): boolean {
    return /^[^@]+@[^@]+$/.test(value);
}

export function validatePhone(value: string): boolean {
    return /[0-9]{10}/.test(value);
}

export function validateOTP(value: string): boolean {
    return /[0-9]{6}/.test(value);
}
