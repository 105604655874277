// application
// @ts-nocheck
import { IProductsList, IProduct, IProductDetails } from '~/interfaces/product';
import { IFilterValues, IListOptions } from '~/interfaces/list';
import { httpRequest } from '~/helpers/http';
import { makePageBasedNavigation } from '~/helpers/utils';
import { IOrderDetails } from '~/interfaces/order';

export interface IGetSearchSuggestionsOptions {
    limitProducts?: number;
    limitCategories?: number;
}

export interface IGetSearchSuggestionsResult {
    products: IProduct[];
    categories: ICategoryV2;
}

export interface ICheckoutItemOptionData {
    name: string;
    value: string;
}

export interface ICheckoutItemData {
    productId: number;
    quantity: number;
}

export interface ICheckoutData {
    id: number;
    shipping_address_id: number;
}

interface IProductSearchResult {
    count: number;
    result: IProduct[];
    results?: IProduct[];
    matches_count?: number;
}

export async function getProductsList(
    options: IListOptions = {},
    filterValues: IFilterValues = {}
): Promise<IProductsList> {
    // General
    const limit = options?.limit || 10;

    const queryParams = {
        ...options,
        ...filterValues,
        start_index: (options.page ?? 0) * 10,
    };

    let response: IProductSearchResult;
    if (queryParams.search) response = await getProductsListBySearch(queryParams);
    else response = await getProducts(queryParams);
    const products: IProduct[] = response.matches_count ? response.result : response.results || [];

    // Page based navigation
    const result = makePageBasedNavigation(
        products,
        limit,
        options?.page || 1,
        (response.matches_count ?? response.count) || 0
    );

    const [items, navigation] = result;

    return Promise.resolve({
        items,
        navigation,
    });
}

export function getProductBySlug(slug: string): Promise<IProductDetails> {
    return new Promise<IProductDetails>((resolve, reject) => {
        httpRequest({
            method: 'get',
            url: `spare-parts/part-details/`,
            queryParams: {
                sku: slug,
            },
        }).then((response) => {
            resolve({ ...response });
        });
    });
}

export function getProductsListBySearch(filterValues: IFilterValues = {}): Promise<IProductSearchResult> {
    return new Promise<IProductSearchResult>((resolve, reject) => {
        httpRequest({
            url: 'spare-parts/part/',
            method: 'get',
            queryParams: {
                search: filterValues.search,
                car_variant_id: filterValues.car_variant_id,
                year: filterValues.year,
                page: filterValues.page,
                start_index: (filterValues.page - 1) * 10,
            },
        })
            .then((response) => {
                resolve(response);
            })
            .catch(() => {
                resolve({ count: 0, result: [] });
            });
    });
}
export function getProducts(filterValues: IFilterValues = {}): Promise<IProductSearchResult> {
    return new Promise<IProductSearchResult>((resolve, reject) => {
        httpRequest({
            url: 'spare-parts/',
            method: 'get',
            queryParams: {
                car_variant_id: filterValues.car_variant_id,
                category: (filterValues.category || '').replaceAll('&', '%26').replaceAll(' ', '+'),
                subcategory: (filterValues.subcategory || '').replaceAll('&', '%26').replaceAll(' ', '+'),
                year: filterValues.year,
                page: filterValues.page,
            },
        })
            .then((response) => {
                resolve(response);
            })
            .catch(() => {
                resolve({ count: 0, result: [] });
            });
    });
}
