import { httpRequest } from '~/helpers/http';
import { getCookieItem } from '~/helpers/utils';
import { IUser } from '~/interfaces/user';

// eslint-disable-next-line import/prefer-default-export
export function getUserData(): Promise<IUser> {
    const userId = getCookieItem('userId');
    return httpRequest<IUser>({
        url: `business/user/${userId}/`,
        method: 'get',
        withAuthToken: true,
    });
}
