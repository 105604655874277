// application
import { getCookieItem, setCookieItem } from '~/helpers/utils';
import { IVehicle } from '~/interfaces/vehicle';
import {
    GARAGE_SET_CURRENT,
    GARAGE_SET_ITEMS,
    GarageSetCurrentAction,
    GarageSetItemsAction,
    GarageThunkAction,
    RESET_GARAGE,
    ResetGarageAction,
    GarageRemoveItemAction,
    GarageAddItemAction,
    GARAGE_ADD_ITEM,
    GARAGE_REMOVE_ITEM,
} from '~/store/garage/garageActionTypes';
import { isVechileEquals } from './garageReducer';

export function garageSetItems(items: IVehicle[]): GarageSetItemsAction {
    return {
        type: GARAGE_SET_ITEMS,
        payload: items,
    };
}

export function garageSetCurrent(vehicle: IVehicle | null): GarageSetCurrentAction {
    return {
        type: GARAGE_SET_CURRENT,
        payload: vehicle,
    };
}

export function garageAddVehicle(vehicle: IVehicle): GarageAddItemAction {
    return {
        type: GARAGE_ADD_ITEM,
        payload: vehicle,
    };
}
export function garageRemoveVehicle(vehicle: IVehicle): GarageRemoveItemAction {
    return {
        type: GARAGE_REMOVE_ITEM,
        payload: vehicle,
    };
}
export function garageReset(): ResetGarageAction {
    return {
        type: RESET_GARAGE,
    };
}

export function resetGarage(): GarageThunkAction<Promise<void>> {
    return (dispatch) =>
        Promise.resolve().then(() => {
            setCookieItem('garage', []);
            dispatch(garageSetItems([]));
        });
}

export function garageAddItem(vehicle: IVehicle): GarageThunkAction<Promise<void>> {
    return (dispatch) =>
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(garageAddVehicle(vehicle));
                resolve();
            }, 250);
        });
}

export function garageRemoveItem(vehicle: IVehicle, vehicles: IVehicle[]): GarageThunkAction<Promise<void>> {
    return (dispatch) =>
        new Promise((resolve) => {
            setTimeout(() => {
                setCookieItem('garage', JSON.stringify(vehicles.filter((item) => !isVechileEquals(item, vehicle))));
                dispatch(garageRemoveVehicle(vehicle));
                resolve();
            }, 250);
        });
}

export function initiateGarage(): GarageThunkAction<Promise<void>> {
    return (dispatch) =>
        new Promise((resolve) => {
            const garage = getCookieItem('garage');
            setTimeout(() => {
                dispatch(garageSetItems(garage ? JSON.parse(garage) : []));
                resolve();
            }, 250);
        });
}
