// react
import React, { useCallback, useEffect, useState } from 'react';
// third-party
import classNames from 'classnames';
import { Modal } from 'reactstrap';
// application
import AsyncAction from '~/components/shared/AsyncAction';
import RadioButton from '~/components/shared/RadioButton';
import VehicleForm from '~/components/shared/VehicleForm';
import { Cross12Svg, RecycleBin16Svg } from '~/svg';
import { IVehicle } from '~/interfaces/vehicle';
import { useGarageAddItem, useGarageRemoveItem, useUserVehicles } from '~/store/garage/garageHooks';
import { isVechileEquals } from '~/store/garage/garageReducer';
import { setCookieItem } from '~/helpers/utils';

interface Props {
    value?: IVehicle | null;
    isOpen?: boolean;
    onClose?: () => void;
    onSelect?: (vehicle: IVehicle | null) => void;
}

function VehiclePickerModal(props: Props) {
    const { value = null, isOpen = false, onClose, onSelect } = props;
    const vehicles = useUserVehicles();
    const garageRemoveItem = useGarageRemoveItem();
    const garageAddItem = useGarageAddItem();
    const [currentPanel, setCurrentPanel] = useState('list');
    const [innerValue, setInnerValue] = useState<IVehicle | null>(null);
    const [controlValue, setControlValue] = useState<IVehicle | null>(null);

    const onSelectClick = () => {
        if (onSelect) {
            onSelect(innerValue);
        }
        if (onClose) {
            onClose();
        }
    };

    const toggle = useCallback(() => {
        if (isOpen && onClose) {
            onClose();
        }
    }, [isOpen, onClose]);

    const onAddVehicleClick = async () => {
        if (!controlValue) {
            return;
        }
        const isVehicleExist = vehicles.some((item) => isVechileEquals(item, controlValue));
        if (!isVehicleExist) {
            setCookieItem('garage', JSON.stringify([...vehicles, controlValue]));
            await garageAddItem(controlValue);
        }

        setCurrentPanel('list');
        setInnerValue(controlValue);
    };

    useEffect(() => {
        if (isOpen) {
            setCurrentPanel('list');
            setInnerValue(value);
        }
    }, [isOpen, value]);

    return (
        <Modal isOpen={isOpen} toggle={toggle} centered className="vehicle-picker-modal">
            <button type="button" className="vehicle-picker-modal__close" onClick={onClose}>
                <Cross12Svg />
            </button>

            <div
                className={classNames('vehicle-picker-modal__panel', {
                    'vehicle-picker-modal__panel--active': currentPanel === 'list' && vehicles.length !== 0,
                })}
            >
                <div className="vehicle-picker-modal__title card-title">Select Vehicle</div>

                <div className="vehicle-picker-modal__text">Select a vehicle to find exact fit parts.</div>

                <div className="vehicles-list">
                    <div className="vehicles-list__body">
                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label className="vehicles-list__item">
                            <RadioButton
                                className="vehicles-list__item-radio"
                                checked={innerValue === null}
                                onChange={() => setInnerValue(null)}
                            />
                            <span className="vehicles-list__item-info">
                                <span className="vehicles-list__item-name">All Vehicles</span>
                            </span>
                        </label>
                        {vehicles.map((vehicle, vehicleIdx) => (
                            // eslint-disable-next-line jsx-a11y/label-has-associated-control
                            <label key={vehicleIdx} className="vehicles-list__item">
                                <RadioButton
                                    className="vehicles-list__item-radio"
                                    checked={!!innerValue && isVechileEquals(vehicle, innerValue)}
                                    onChange={() => setInnerValue(vehicle)}
                                />
                                <span className="vehicles-list__item-info">
                                    <span className="vehicles-list__item-name">
                                        {`${vehicle.year} ${vehicle.brand} ${vehicle.model}`}
                                    </span>
                                    <span className="vehicles-list__item-details">{`Variant ${vehicle.variant}`}</span>
                                </span>

                                <AsyncAction
                                    action={() => garageRemoveItem(vehicle, vehicles)}
                                    render={({ run, loading }) => (
                                        <button
                                            type="button"
                                            className={classNames('vehicles-list__item-remove', {
                                                'vehicles-list__item-remove--loading': loading,
                                            })}
                                            onClick={run}
                                        >
                                            <RecycleBin16Svg />
                                        </button>
                                    )}
                                />
                            </label>
                        ))}
                    </div>
                </div>

                <button
                    type="button"
                    className="mt-2 btn btn-sm btn-secondary btn-block vehicle-picker-modal__add-button"
                    onClick={() => setCurrentPanel('form')}
                >
                    Add Vehicle
                </button>

                <div className="vehicle-picker-modal__actions">
                    <button type="button" className="btn btn-sm btn-secondary" onClick={onClose}>
                        Cancel
                    </button>
                    <button type="button" className="btn btn-sm btn-primary" onClick={onSelectClick}>
                        Select Vehicle
                    </button>
                </div>
            </div>

            <div
                className={classNames('vehicle-picker-modal__panel', {
                    'vehicle-picker-modal__panel--active': currentPanel === 'form' || vehicles.length === 0,
                })}
            >
                <div className="vehicle-picker-modal__title card-title">Add A Vehicle</div>
                <VehicleForm location="modal" onVehicleChange={setControlValue} />
                <div className="vehicle-picker-modal__actions">
                    {vehicles.length !== 0 && (
                        <button
                            type="button"
                            className="btn btn-sm btn-secondary"
                            onClick={() => setCurrentPanel('list')}
                        >
                            Back to list
                        </button>
                    )}

                    <AsyncAction
                        action={onAddVehicleClick}
                        render={({ run, loading }) => (
                            <button
                                type="button"
                                className={classNames('btn', 'btn-sm', 'btn-primary', {
                                    'btn-loading': loading,
                                })}
                                disabled={controlValue === null}
                                onClick={run}
                            >
                                Add Vehicle
                            </button>
                        )}
                    />
                </div>
            </div>
        </Modal>
    );
}

export default VehiclePickerModal;
