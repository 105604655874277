// react
import { useMemo, useState } from 'react';
// third-party
import { useForm } from 'react-hook-form';
// application
import { useAsyncAction } from '~/store/hooks';
import { useUserSendOTP, useUserSignIn } from '~/store/user/userHooks';

interface ISignInFormOptions {
    onSuccess?: (value?: any, data?: any) => void;
}

export interface ISignInForm {
    phone: string;
    otp: string;
}
export interface ISendOTPForm {
    phone: string;
}

export function useSignInForm(options: ISignInFormOptions = {}) {
    const signIn = useUserSignIn();
    const { onSuccess } = options;
    const [serverError, setServerError] = useState<string | null>(null);
    const methods = useForm<ISignInForm>({
        defaultValues: {
            phone: '',
        },
    });
    const { handleSubmit, setValue, getValues } = methods;
    const [submit, submitInProgress] = useAsyncAction(
        (data: ISignInForm) => {
            setServerError(null);

            return signIn(data.phone, data.otp).then(
                () => {
                    if (onSuccess) {
                        onSuccess();
                    }
                },
                (error: Error) => {
                    setServerError(error.message);
                }
            );
        },
        [signIn, setServerError, onSuccess]
    );

    return {
        submit: useMemo(() => handleSubmit(submit), [handleSubmit, submit]),
        submitInProgress: submitInProgress || methods.formState.isSubmitting,
        serverError,
        errors: methods.formState.errors,
        register: methods.register,
        watch: methods.watch,
        setValue,
        getValues,
    };
}

export function useSendOTPForm(options: ISignInFormOptions = {}) {
    const sendOTP = useUserSendOTP();
    const { onSuccess } = options;
    const [serverError, setServerError] = useState<string | null>(null);
    const methods = useForm<ISendOTPForm>({
        defaultValues: {
            phone: '',
        },
    });
    const { handleSubmit } = methods;
    const [submit, submitInProgress] = useAsyncAction(
        (data: ISendOTPForm) => {
            setServerError(null);

            return sendOTP(data.phone).then(
                (value) => {
                    if (onSuccess) {
                        onSuccess(value, data);
                    }
                },
                (error: Error) => {
                    // @ts-ignore
                    setServerError(error?.error?.data?.status);
                }
            );
        },
        [sendOTP, setServerError, onSuccess]
    );

    return {
        submit: useMemo(() => handleSubmit(submit), [handleSubmit, submit]),
        submitInProgress: submitInProgress || methods.formState.isSubmitting,
        serverError,
        errors: methods.formState.errors,
        register: methods.register,
        watch: methods.watch,
    };
}
