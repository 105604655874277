// eslint-disable-next-line import/no-extraneous-dependencies
import Cookies from 'js-cookie';
/* eslint-disable import/prefer-default-export */
import { IPageBasedNavigation } from '~/interfaces/list';

export const isNull = (value: any) => value === null;

export const isUndefined = (value: any) => typeof value === 'undefined';

// LOCAL STORAGE Methods

export const getCookieItem = (key = '') => {
    if (typeof window === 'undefined') {
        return null;
    }
    const value = Cookies.get(key);
    if (typeof value === 'undefined') {
        return null;
    }
    return value;
};

export const setCookieItem = (key = '', value: any) => {
    Cookies.set(key, value);
};

export const clearCookieItem = (key = '') => {
    Cookies.remove(key);
};

export const clearCookieItems = () =>
    Object.keys(Cookies.get()).forEach((cookieName) => {
        Cookies.remove(cookieName);
    });

export const getAuthToken = () => getCookieItem('userToken');

export const setAuthToken = (value: any) => setCookieItem('userToken', value);

export const resetAuthToken = () => clearCookieItem('userToken');

export function makePageBasedNavigation<T>(
    items: T[],
    limit: number,
    page: number,
    total: number
): [T[], IPageBasedNavigation] {
    const pages = Math.ceil(total / limit);
    const from = (page - 1) * limit + 1;
    const to = Math.min(page * limit, total);

    return [
        items,
        {
            type: 'page',
            limit,
            total,
            page,
            pages,
            from,
            to,
        },
    ];
}

export const priceFormatter = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

export const convertToPascalCase = (str: string) =>
    str.replace(/(\w)(\w*)/g, function (g0, g1, g2) {
        return g1.toUpperCase() + g2.toLowerCase();
    });
