// application
import { useAppAction, useAppSelector } from '~/store/hooks';
import { USER_NAMESPACE } from '~/store/user/userReducer';
import { fetchUser, userEditProfile, userSendOTP, userSignIn, userSignOut } from '~/store/user/userAction';

export const useUser = () => useAppSelector((state) => state[USER_NAMESPACE].current);

export const useUserToken = () => useAppSelector((state) => state[USER_NAMESPACE].token);

export const useUserAddress = () => useAppSelector((state) => state[USER_NAMESPACE].currentAddress);

export const useUserSignIn = () => useAppAction(userSignIn);

export const useFetchUser = () => useAppAction(fetchUser);

export const useUserSendOTP = () => useAppAction(userSendOTP);

export const useUserSignOut = () => useAppAction(userSignOut);

export const useUserEditProfile = () => useAppAction(userEditProfile);
