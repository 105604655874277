// react
import React, { useRef, useState } from 'react';
// third-party
import classNames from 'classnames';
// application
import AppImage from '~/components/shared/AppImage';
import AppLink from '~/components/shared/AppLink';
import url from '~/services/url';
import VehiclePickerModal from '~/components/shared/VehiclePickerModal';
import { IVehicle } from '~/interfaces/vehicle';
import { useCart } from '~/store/cart/cartHooks';
import { useGarageCurrent, useGarageSetCurrent } from '~/store/garage/garageHooks';
import { useGlobalMousedown } from '~/services/hooks';
import { useMobileMenuOpen } from '~/store/mobile-menu/mobileMenuHooks';
import { Car20Svg, Cart20Svg, Cross20Svg, Menu18x14Svg, Person20Svg, Search20Svg } from '~/svg';
import { useUserToken } from '~/store/user/userHooks';
import { hrefToRouterArgs } from '~/services/router';
import { useRouter } from 'next/router';
import Image from 'next/image';

function MobileHeader() {
    const router = useRouter();
    const mobileMenuOpen = useMobileMenuOpen();
    const userToken = useUserToken();
    const cart = useCart();
    const vehicle: IVehicle | null = useGarageCurrent();
    const garageSetCurrent = useGarageSetCurrent();
    const [query, setQuery] = useState('');
    const searchFormRef = useRef<HTMLDivElement | null>(null);
    const searchInputRef = useRef<HTMLInputElement | null>(null);
    const searchIndicatorRef = useRef<HTMLDivElement | null>(null);
    const [searchIsOpen, setSearchIsOpen] = useState(false);
    const [vehiclePickerIsOpen, setVehiclePickerIsOpen] = useState(false);

    const searchCancelFnRef = useRef(() => {});

    const search = (value: string) => {
        searchCancelFnRef.current();

        // let canceled = false;

        searchCancelFnRef.current = () => {
            // canceled = true;
        };

        // getSearchSuggestions(value, {
        //     limitProducts: 3,
        //     limitCategories: 4,
        // }).then((result) => {
        //     if (canceled) {
        //         return;
        //     }

        //     if (result.products.length === 0 && result.categories.length === 0) {
        //         setHasSuggestions(false);
        //         return;
        //     }

        //     setHasSuggestions(true);
        //     setProducts(result.products);
        //     setCategories(result.categories);
        // });

        setQuery(value);
    };

    const handleInputChange = (event: React.FormEvent<HTMLInputElement>) => {
        const input = event.currentTarget;

        search(input.value);
    };


    const openSearch = () => {
        setSearchIsOpen(true);

        if (searchInputRef.current) {
            searchInputRef.current.focus();
        }
    };

    const closeSearch = () => {
        setSearchIsOpen(false);
    };

    const openVehiclePicker = () => {
        setVehiclePickerIsOpen(true);
    };

    const onVehiclePickerClose = () => {
        setVehiclePickerIsOpen(false);

        if (searchInputRef.current) {
            searchInputRef.current.focus();
        }
    };

    const onVehiclePickerSelect = (selectedVehicle: IVehicle | null) => {
        garageSetCurrent(selectedVehicle);
    };

    const onSearchSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        router
            .push(
                ...hrefToRouterArgs(
                    url.products({
                        filters: {
                            search: query,
                            ...vehicle,
                        },
                    })
                )
            )
            .then(() => setQuery(''));
    };

    useGlobalMousedown(
        (event) => {
            const outsideIndicator =
                searchIndicatorRef.current && !searchIndicatorRef.current.contains(event.target as HTMLElement);
            const outsideForm = searchFormRef.current && !searchFormRef.current.contains(event.target as HTMLElement);

            if (outsideIndicator && outsideForm) {
                if (searchIsOpen && !vehiclePickerIsOpen) {
                    closeSearch();
                }
            }
        },
        [searchFormRef, searchIndicatorRef, searchIsOpen, vehiclePickerIsOpen]
    );

    const searchPlaceholder = vehicle
        ? `Search for ${vehicle.year} ${vehicle.brand} ${vehicle.model}`
        : 'Enter Keyword or Part Number';

    return (
        <div className="mobile-header">
            <VehiclePickerModal
                value={vehicle}
                isOpen={vehiclePickerIsOpen}
                onClose={onVehiclePickerClose}
                onSelect={onVehiclePickerSelect}
            />

            <div className="container">
                <div className="mobile-header__body">
                    <button className="mobile-header__menu-button" type="button" onClick={mobileMenuOpen}>
                        <Menu18x14Svg />
                    </button>
                    <AppLink href={url.home()} className="mobile-header__logo">
                        <Image height={48} width={153} alt="logo" src="/images/logo.png" />
                    </AppLink>
                    <div
                        ref={searchFormRef}
                        className={classNames('mobile-header__search mobile-search', {
                            'mobile-header__search--open': searchIsOpen,
                        })}
                    >
                        <form className="mobile-search__body" onSubmit={onSearchSubmit}>
                            <label className="sr-only" htmlFor="mobile-site-search">
                                Search for
                            </label>
                            <input
                                ref={searchInputRef}
                                type="text"
                                value={query}
                                id="mobile-site-search"
                                className="mobile-search__input"
                                onChange={handleInputChange}
                                placeholder={searchPlaceholder}
                            />
                            <button type="button" className="mobile-search__vehicle-picker" onClick={openVehiclePicker}>
                                <Car20Svg />
                                <span className="mobile-search__vehicle-picker-label">Vehicle</span>
                            </button>
                            <button type="submit" className="mobile-search__button mobile-search__button--search">
                                <Search20Svg />
                            </button>
                            <button
                                type="button"
                                className="mobile-search__button mobile-search__button--close"
                                onClick={closeSearch}
                            >
                                <Cross20Svg />
                            </button>
                            <div className="mobile-search__field" />
                        </form>
                    </div>
                    <div className="mobile-header__indicators">
                        <div className="mobile-indicator d-md-none" ref={searchIndicatorRef}>
                            <button type="button" className="mobile-indicator__button" onClick={openSearch}>
                                <span className="mobile-indicator__icon">
                                    <Search20Svg />
                                </span>
                            </button>
                        </div>
                        <div className="mobile-indicator d-none d-md-block">
                            <AppLink
                                href={userToken ? url.accountDashboard() : url.signIn()}
                                className="mobile-indicator__button"
                            >
                                <span className="mobile-indicator__icon">
                                    <Person20Svg />
                                </span>
                            </AppLink>
                        </div>
                        <div className="mobile-indicator">
                            <AppLink href={url.cart()} className="mobile-indicator__button">
                                <span className="mobile-indicator__icon">
                                    <Cart20Svg />
                                    {cart.order_items.length > 0 && (
                                        <span className="mobile-indicator__counter">{cart.order_items.length}</span>
                                    )}
                                </span>
                            </AppLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default React.memo(MobileHeader);
