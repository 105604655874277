// react
import React from 'react';
// third-party
import classNames from 'classnames';
// application
import AppImage from '~/components/shared/AppImage';
import AppLink from '~/components/shared/AppLink';
import AsyncAction from '~/components/shared/AsyncAction';
import CurrencyFormat from '~/components/shared/CurrencyFormat';
import url from '~/services/url';
import { Cross10Svg } from '~/svg';
import { useCartItems, useCartRemoveItem } from '~/store/cart/cartHooks';

interface Props {
    onCloseMenu: () => void;
}

function Dropcart(props: Props) {
    const { onCloseMenu } = props;
    const items = useCartItems();
    const cartRemoveItem = useCartRemoveItem();

    return (
        <div className="dropcart">
            {items.length === 0 && <div className="dropcart__empty">Your shopping cart is empty!</div>}
            {items.length > 0 && (
                <React.Fragment>
                    <ul className="dropcart__list">
                        {items.map(({ id, quantity, image, part_name, amount, sku_id }, index) => (
                            <React.Fragment key={index}>
                                <li className="dropcart__item">
                                    <div className="dropcart__item-image image image--type--product">
                                        <AppLink
                                            className="image__body"
                                            href={url.product(sku_id)}
                                            onClick={onCloseMenu}
                                        >
                                            <AppImage className="image__tag" src={image || ''} />
                                        </AppLink>
                                    </div>
                                    <div className="dropcart__item-info">
                                        <div className="dropcart__item-name">
                                            <AppLink href={url.product(sku_id)} onClick={onCloseMenu}>
                                                {part_name.replaceAll(',', ', ')}
                                            </AppLink>
                                        </div>
                                        <div className="dropcart__item-meta">
                                            <div className="dropcart__item-quantity">
                                                {quantity} 
                                                {/* {lot_size > 1 && ` X Pack of ${lot_size}`} */}
                                            </div>
                                            <div className="dropcart__item-price">
                                                <CurrencyFormat value={Number(amount) * quantity} />
                                            </div>
                                        </div>
                                    </div>
                                    <AsyncAction
                                        action={() => cartRemoveItem(id)}
                                        render={({ run, loading }) => (
                                            <button
                                                type="button"
                                                className={classNames('dropcart__item-remove', {
                                                    'dropcart__item-remove--loading': loading,
                                                })}
                                                onClick={run}
                                            >
                                                <Cross10Svg />
                                            </button>
                                        )}
                                    />
                                </li>
                                <li className="dropcart__divider" role="presentation" />
                            </React.Fragment>
                        ))}
                    </ul>
                    {/* <div className="dropcart__totals">
                        <table>
                            <tbody>
                                <tr>
                                    <th>Subtotal</th>
                                    <td>
                                        <CurrencyFormat value={cart.subtotal} />
                                    </td>
                                </tr>
                                <tr>
                                    <th>{`Tax ${cart.tax.rate}%`}</th>
                                    <td>
                                        <CurrencyFormat value={cart.tax.price} />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Total</th>
                                    <td>
                                        <CurrencyFormat value={cart.total} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div> */}
                    <div className="dropcart__actions">
                        <AppLink href={url.cart()} className="btn btn-secondary" onClick={onCloseMenu}>
                            View Cart
                        </AppLink>
                        <AppLink href={url.checkout()} className="btn btn-primary" onClick={onCloseMenu}>
                            Checkout
                        </AppLink>
                    </div>
                </React.Fragment>
            )}
        </div>
    );
}

export default Dropcart;
