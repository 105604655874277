import { httpRequest } from '~/helpers/http';

export * from './account';
export * from './addresses';
export * from './categories';
export * from './orders';
export * from './products';
export * from './user';

export const getMasterData = () => {
    return httpRequest({
        url: 'spare-parts/master-data/',
        method: 'get',
    });
};
