// react
import Image from 'next/image';
import React from 'react';
// application
import BlockSpace from '~/components/blocks/BlockSpace';
import { VMotiveLogoSvg } from '~/svg';
// import AppImage from '../shared/AppImage';
// import AppSlick, { ISlickProps } from '../shared/AppSlick';

// const slickSettings: ISlickProps = {
//     dots: true,
//     arrows: false,
//     infinite: true,
//     speed: 150,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     autoplay: true,
// };
// const productImages = [
//     '/images/product/AirFilter.png',
//     '/images/product/BrakeFluid.png',
//     '/images/product/CoolantGreen.png',
//     '/images/product/BrakePad.png',
//     '/images/product/EngineFlush.png',
//     '/images/product/MettlePlus.png',
//     '/images/product/CoolantRed.png',
//     '/images/product/RadiatorFlush.png',
//     '/images/product/MettlePrime.png',
// ];

function ComingSoon() {
    return (
        <React.Fragment>
            {/* <PageTitle>V-Motive</PageTitle> */}
            <div className="loading_screen">
                <BlockSpace layout="spaceship-ledge-height" />
                <div className="logo__image">
                    {/* logo */}
                    <VMotiveLogoSvg />
                    {/* logo / end */}
                </div>
                <BlockSpace layout="before-footer" />
            </div>
        </React.Fragment>
    );
}

export default ComingSoon;
