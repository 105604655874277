// react
import { useEffect, useRef, useState } from 'react';
// application
import { IVehicle } from '~/interfaces/vehicle';
import { useMobileMenu } from '~/store/mobile-menu/mobileMenuHooks';
import { ICarBrand, ICarGeneration, ICarModel, ICarVariant } from '~/store/mobile-menu/mobileMenuTypes';

interface VehicleSelectItemDef<T = any> {
    key: string;
    label: string;
    placeholder: string;
    optionsSource: (...args: any[]) => T[];
    serializeOptionFn?: (option: T, item: VehicleSelectItem<T>) => string;
    deserializeOptionFn?: (value: string, item: VehicleSelectItem<T>) => T;
    defaultObject: T;
}

interface VehicleSelectItem<T = any> extends VehicleSelectItemDef<T> {
    value: string;
    loading: boolean;
    options: T[];
    disabled: boolean;
}

function makeItems(itemsDef: VehicleSelectItemDef[]): VehicleSelectItem[] {
    return itemsDef.map((itemDef, index) => ({
        ...itemDef,
        value: 'none',
        loading: false,
        options: [],
        disabled: index !== 0,
    }));
}

function getItemValue(item: VehicleSelectItem): any {
    const { value: itemValue } = item;

    if (itemValue !== 'none' && item.deserializeOptionFn) {
        return item.deserializeOptionFn(itemValue, item);
    }

    return itemValue;
}

function getItemValues(items: VehicleSelectItem[]): any[] {
    return items.reduce<any[]>((acc, prevItem) => [...acc, getItemValue(prevItem)], []);
}

function serializeOption(option: any, item: VehicleSelectItem): string {
    if (item.serializeOptionFn) {
        return item.serializeOptionFn(option, item);
    }

    return option;
}

interface IOptions {
    onChange?: (vehicle: IVehicle | null) => void;
}

export default function useVehicleForm(options: IOptions = {}) {
    const { onChange } = options;
    const cancelPrevRequestRef = useRef(() => {});
    const shop = useMobileMenu();
    const [items, setItems] = useState(
        makeItems([
            {
                key: 'brand',
                label: 'Brand',
                placeholder: 'Select Brand',
                defaultObject: {
                    id: null,
                    brand: 'none',
                },
                optionsSource: () => shop.car_brands,
                serializeOptionFn: (option: ICarBrand) => option.brand,
                deserializeOptionFn: (option: string, item: VehicleSelectItem<ICarBrand>) =>
                    item.options.find((x) => x.brand === option)!,
            },
            {
                key: 'model',
                label: 'Model',
                placeholder: 'Select Model',
                defaultObject: {
                    id: null,
                    brand_id: null,
                    model: 'No Model Available',
                },
                optionsSource: (args) => shop.car_models[args.id],
                serializeOptionFn: (option: ICarModel) => option.model,
                deserializeOptionFn: (option: string, item: VehicleSelectItem<ICarModel>) =>
                    item.options.find((x) => x.model === option)!,
            },
            {
                key: 'year',
                label: 'Year',
                placeholder: 'Select Year',
                defaultObject: 'none',
                optionsSource: () => shop.car_manufacture_years,
            },
            {
                key: 'variant',
                label: 'Variant',
                placeholder: 'Select Variant',
                defaultObject: {
                    id: null,
                    generation_id: null,
                    variant: 'none',
                    generation: 'none',
                },
                optionsSource: (...args) => {
                    const list = <any>[];
                    shop.car_generations.forEach((element: ICarGeneration) => {
                        if (element.model_id !== args[1].id) return;
                        shop.car_variants
                            .filter((each) => each.generation_id === element.id)
                            .forEach((each) => {
                                list.push({ ...each, generation: element.generation });
                            });
                    });
                    return list;
                },
                serializeOptionFn: (option: ICarVariant) => `${option.generation} | ${option.variant}`,
                deserializeOptionFn: (option: string, item: VehicleSelectItem<ICarVariant>) =>
                    item.options.find((x) => `${x.generation} | ${x.variant}` === option)!,
            },
        ])
    );

    const load = async (items: VehicleSelectItem[], index: number) => {
        cancelPrevRequestRef.current();

        let canceled = false;
        cancelPrevRequestRef.current = () => {
            canceled = true;
        };

        setItems((prevItems) => [
            ...prevItems.map((prevItem, prevItemIdx) =>
                prevItemIdx !== index
                    ? prevItem
                    : {
                          ...prevItem,
                          loading: true,
                      }
            ),
        ]);

        const item = items[index];
        const args = getItemValues(items.slice(0, index));

        let optionsSource = <any[]>[];

        if (args.length === 0 || args.slice().pop() !== 'none') {
            optionsSource = item.optionsSource(...args);
        }

        const options = optionsSource;

        if (canceled) {
            return;
        }

        setItems((prevItems) => [
            ...prevItems.map((prevItem, prevItemIdx) =>
                prevItemIdx !== index
                    ? prevItem
                    : {
                          ...prevItem,
                          options: options.length > 0 ? options : [item.defaultObject],
                          loading: false,
                      }
            ),
        ]);
    };

    const onItemValueChange = (index: number, value: string): void => {
        const nextItemIdx = index + 1;

        setItems((prevItems) => {
            let resultItems = prevItems;

            resultItems = [
                ...resultItems.map((prevItem, prevItemIdx) =>
                    prevItemIdx !== index
                        ? prevItem
                        : {
                              ...prevItem,
                              value,
                          }
                ),
            ];

            resultItems = [
                ...resultItems.map((prevItem, prevItemIdx) =>
                    prevItemIdx <= index
                        ? prevItem
                        : {
                              ...prevItem,
                              value: 'none',
                              options: [],
                              disabled: true,
                          }
                ),
            ];

            if (value !== 'none' && value !== 'No Model Available' && resultItems[nextItemIdx]) {
                resultItems = [
                    ...resultItems.map((prevItem, prevItemIdx) =>
                        prevItemIdx !== nextItemIdx
                            ? prevItem
                            : {
                                  ...prevItem,
                                  disabled: false,
                              }
                    ),
                ];
            }

            return resultItems;
        });

        if (value === 'none' || value === 'No Model Available') {
            if (onChange) {
                onChange(null);
            }
        } else if (!items[nextItemIdx] && onChange) {
            const item = items[index];
            let itemValue: Partial<ICarVariant> = { id: 0 };
            if (item.deserializeOptionFn) itemValue = item.deserializeOptionFn(value, item);
            onChange({
                brand: items[0].value,
                model: items[1].value,
                year: items[2].value,
                variant: value,
                car_variant_id: itemValue.id?.toString() || '0',
            });
        }
    };

    // Load items.
    useEffect(() => {
        let prevValue = null;

        for (let i = 0; i < items.length; i += 1) {
            const item = items[i];

            if (
                prevValue !== 'none' &&
                prevValue !== 'No Model Available' &&
                item.options.length === 0 &&
                !item.loading
            ) {
                load(items, i).then();

                return;
            }

            prevValue = item.value;
        }
    }, [items]);

    return {
        items,
        onItemValueChange,
        serializeOption,
    };
}
