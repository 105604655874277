// application
import { IMobileMenuLink } from '~/interfaces/mobile-menu-link';

const dataMobileMenuLinks: IMobileMenuLink[] = [
    {
        title: 'Home',
        url: '/',
    },
    { title: 'Login & Register', url: '/account/login' },
    {
        title: 'Account',
        url: '/account/dashboard',
        submenu: [
            { title: 'Dashboard', url: '/account/dashboard' },
            { title: 'Garage', url: '/account/garage' },
            { title: 'Edit Profile', url: '/account/profile' },
            { title: 'Order History', url: '/account/orders' },
            {
                title: 'Order Details',
                url: {
                    href: '/account/orders/[id]?id=1',
                    as: '/account/orders/1',
                },
            },
            { title: 'Address Book', url: '/account/addresses' },
            {
                title: 'Edit Address',
                url: {
                    href: '/account/addresses/[id]?id=new',
                    as: '/account/addresses/new',
                },
            },
        ],
    },
    { title: 'About Us', url: '/about-us' },
    { title: 'FAQ', url: '/faq' },
    { title: 'Contact Us', url: '/contact-us' },
];

export default dataMobileMenuLinks;
