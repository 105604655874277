// application
import { httpRequest } from '~/helpers/http';
import { getCookieItem } from '~/helpers/utils';

export interface IEditProfileData {
    first_name: string;
    last_name: string;
    email: string;
    phone?: string;
}

export function accountSignIn(phone: string, otp: string): Promise<any> {
    return httpRequest({
        method: 'post',
        url: 'business/user/validate-auth-code/',
        data: { auth_code: otp, phone },
    });
}

export function accountSendOTP(phone: string): Promise<any> {
    return httpRequest({
        method: 'post',
        url: 'business/user/send-signup-code/',
        data: { phone, group: 'B2CUser' },
    });
}

export function accountSignOut(): Promise<void> {
    return Promise.resolve();
}

export function accountEditProfile(data: Partial<IEditProfileData>): Promise<any> {
    const userId = getCookieItem('userId');
    return httpRequest({
        method: 'patch',
        url: `business/user/${userId}/`,
        data: { ...data, name: `${data.first_name} ${data.last_name}` },
        withAuthToken: true,
    });
}
