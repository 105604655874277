// third-party
import Cookies from 'js-cookie';
import thunk from 'redux-thunk';
import { createWrapper, MakeStore } from 'next-redux-wrapper';
import { applyMiddleware, createStore, Middleware, Store } from 'redux';
// application
import rootReducer from '~/store/root/rootReducer';
import version from '~/store/version';
import { IRootState } from '~/store/root/rootTypes';
import { getCookieItem } from '~/helpers/utils';
import { USER_NAMESPACE } from './user/userReducer';
import { CART_NAMESPACE } from './cart/cartReducer';

const STORAGE_KEY = 'reduxstore';

export const save = (state: any) => {
    try {
        Cookies.set(STORAGE_KEY, JSON.stringify(state));
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
    }
};

export const addItemToLocalStorage = (key: string, value: any) => {
    try {
        const json = JSON.parse(Cookies.get(STORAGE_KEY) ?? '');
        json[key] = value;
        save(json);
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
    }
};

export const getItemFromLocalStorage = (key: string): any => {
    try {
        const jsonString = Cookies.get(STORAGE_KEY);
        const json = JSON.parse(jsonString ?? '{}');
        return json[key];
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
    }
    return null;
};

export const load = () => {
    if (!process.browser) {
        return undefined;
    }

    let state;

    try {
        state = Cookies.get(STORAGE_KEY);
        const userToken = getCookieItem('userToken');
        if (typeof state === 'string') {
            state = JSON.parse(state);
            state[USER_NAMESPACE].token = userToken;
        }

        // if (state && state.version !== version) {
        //     state = undefined;
        // }
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
    }

    return state || undefined;
};

const bindMiddleware = (...middleware: Middleware[]) => {
    if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line global-require
        const { composeWithDevTools } = require('redux-devtools-extension');

        return composeWithDevTools(applyMiddleware(...middleware));
    }

    return applyMiddleware(...middleware);
};

const makeStore: MakeStore<Store<IRootState>> = () => createStore(rootReducer, bindMiddleware(thunk));

export const wrapper = createWrapper<Store<IRootState>>(makeStore);
