/* eslint-disable no-nested-ternary */
// react
import Head from 'next/head';
import React, { PropsWithChildren } from 'react';
// third-party
import classNames from 'classnames';
import { ToastContainer } from 'react-toastify';
// application
import Footer from '~/components/footer/Footer';
import Header from '~/components/header/Header';
import MobileHeader from '~/components/mobile/MobileHeader';
import MobileMenu from '~/components/mobile/MobileMenu';
import Quickview from '~/components/shared/Quickview';
import { useOptions } from '~/store/options/optionsHooks';
import ComingSoon from './site/ComingSoon';
import { useMenuItemIsLoading } from '~/store/mobile-menu/mobileMenuHooks';

interface Props extends PropsWithChildren<{}> {}

function Layout(props: Props) {
    const { children } = props;
    const isLoading = useMenuItemIsLoading();
    const { desktopHeaderLayout, desktopHeaderScheme, mobileHeaderVariant } = useOptions();
    const desktopVariantClass = `${desktopHeaderLayout}-${desktopHeaderScheme}`;
    const mobileVariantClass = `mobile-${mobileHeaderVariant}`;

    const classes = classNames(
        'site',
        `site--desktop-header--${desktopVariantClass}`,
        `site--mobile-header--${mobileVariantClass}`
    );

    return (
        <div className={classes}>
            <Head>
                <script type="application/ld+json">
                    {JSON.stringify({
                        '@context': 'https://schema.org',
                        '@type': 'AutoRepair',
                        name: 'Fixcraft',
                        image: 'https://fixcraft.s3.amazonaws.com/static/fixcraft/public/img/website-logo-new-0d45dbcdc6.png',
                        '@id': '',
                        url: 'https://vmotive.in/',
                        telephone: '+918181077172',
                        address: {
                            '@type': 'PostalAddress',
                            streetAddress: ' B37, 4th floor, thinkvalley building, Sector 32',
                            addressLocality: 'Gurugram',
                            postalCode: '122003',
                            addressCountry: 'IN',
                        },
                        openingHoursSpecification: {
                            '@type': 'OpeningHoursSpecification',
                            dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
                            opens: '000',
                            closes: '18:00',
                        },
                        sameAs: ['https://www.facebook.com/fixcraftauto', 'https://www.instagram.com/fixcraft.in/'],
                    })}
                    {typeof window !== 'undefined' && (
                        <meta
                            property="og:title"
                            content={
                                // eslint-disable-next-line no-nested-ternary
                                window.location.href === 'https://vmotive.in/products'
                                    ? 'Our Products'
                                    : window.location.href === 'https://vmotive.in/contact-us'
                                    ? 'Contact Us'
                                    : ''
                            }
                        />
                    )}
                    {typeof window !== 'undefined' && (
                        <meta
                            property="og:description"
                            content={
                                // eslint-disable-next-line no-nested-ternary
                                window.location.href === 'https://vmotive.in/products'
                                    ? 'V-Motive offers parts from OEMs and V-Motive branded products. We have a wide product range including Engine Oils, Filters, Brake Pads, Gear Oils and much more'
                                    : window.location.href === 'https://vmotive.in/contact-us'
                                    ? 'V-Motive provides genuine and authentic spare parts for several car brands. Join V-Motive Retail - boost your auto spare parts business and annual income'
                                    : ''
                            }
                        />
                    )}
                    <meta property="og:image" content="" />
                    <meta property="og:image:secure_url" content="" />
                    <meta property="og:image:width" content="400" />
                    <meta property="og:image:height" content="300" />
                    <meta property="og:site_name" content="Vmotive" />
                    {typeof window !== 'undefined' && <meta property="og:url" content={window.location.href} />}
                </script>
            </Head>
            {isLoading ? (
                <ComingSoon />
            ) : (
                <>
                    <ToastContainer autoClose={5000} hideProgressBar />

                    <div className="site__container">
                        <header className="site__mobile-header">
                            <MobileHeader />
                        </header>

                        <header className="site__header">
                            <Header />
                        </header>

                        <div className="site__body">{children}</div>

                        <footer className="site__footer">
                            <Footer />
                        </footer>
                    </div>

                    <MobileMenu />

                    <Quickview />
                </>
            )}
        </div>
    );
}

export default Layout;
