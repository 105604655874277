// react
import React, { FunctionComponent } from 'react';
// application
import AppLink from '~/components/shared/AppLink';

const FooterNewsletter: FunctionComponent<React.HTMLAttributes<HTMLElement>> = () => {
    const socialLinks = [
        { type: 'facebook', url: 'https://www.facebook.com/fixcraftauto', icon: 'fab fa-facebook-f' },
        { type: 'youtube', url: 'https://www.youtube.com/@fixcraftcarcollisiondamage141', icon: 'fab fa-youtube' },
        { type: 'instagram', url: 'https://www.instagram.com/fixcraft.in', icon: 'fab fa-instagram' },
    ];

    return (
        <div className="footer-newsletter">
            <h5 className="footer-newsletter__title">Connect With Us </h5>

            <div className="footer-newsletter__social-links social-links">
                <ul className="social-links__list">
                    {socialLinks.map((link, index) => (
                        <li key={index} className={`social-links__item social-links__item--${link.type}`}>
                            <AppLink href={link.url} target="_blank">
                                <i className={link.icon} />
                            </AppLink>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default FooterNewsletter;
