// react
import React, { useCallback, useState } from 'react';
// third-party
import classNames from 'classnames';
// application
import AppImage from '~/components/shared/AppImage';
import AppLink from '~/components/shared/AppLink';
import url from '~/services/url';
import { useSendOTPForm, useSignInForm } from '~/services/forms/sign-in';
import { useUser, useUserSignOut } from '~/store/user/userHooks';
import { validatePhone } from '~/services/validators';
import { useRouter } from 'next/router';

interface Props {
    onCloseMenu: () => void;
}

function AccountMenu(props: Props) {
    const { onCloseMenu } = props;
    const [otpSent, setOtpSent] = useState(false);
    const user = useUser();
    const router = useRouter();
    const userSignOut = useUserSignOut();

    const signInForm = useSignInForm({
        onSuccess: useCallback(() => {
            if (onCloseMenu) {
                onCloseMenu();
            }
        }, [onCloseMenu]),
    });
    const sendOTPForm = useSendOTPForm({
        onSuccess: (value, data) => {
            setOtpSent(true);
            signInForm.setValue('phone', data.phone);
        },
    });

    const onLogOutButtonClick = () => {
        userSignOut().then(() => {
            if (onCloseMenu) {
                onCloseMenu();
            }
            router.push('/');
        });
    };

    return (
        <div className="account-menu">
            {user === null &&
                (otpSent ? (
                    <form className="account-menu__form" onSubmit={signInForm.submit}>
                        <div className="account-menu__form-title">Log In to Your Account</div>
                        {signInForm.serverError && (
                            <div className="alert alert-xs alert-danger mt-n2">{signInForm.serverError}</div>
                        )}
                        <div className="form-group">
                            <label htmlFor="header-signin-email" className="sr-only">
                                Phone Number
                            </label>
                            <input
                                id="header-signin-email"
                                type="text"
                                className={classNames('form-control', 'form-control-sm', {
                                    'is-invalid': signInForm.errors.phone,
                                })}
                                disabled
                                placeholder="Phone No."
                                {...signInForm.register('phone', {
                                    required: true,
                                    validate: { phone: validatePhone },
                                })}
                            />
                            <div className="invalid-feedback">
                                {signInForm.errors.phone?.type === 'required' && 'Required field.'}
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="header-signin-password" className="sr-only">
                                Verify OTP
                            </label>
                            <div
                                className={classNames('account-menu__form-forgot', {
                                    'is-invalid': signInForm.errors.otp,
                                })}
                            >
                                <input
                                    id="header-signin-password"
                                    className={classNames('form-control', 'form-control-sm', {
                                        'is-invalid': signInForm.errors.otp,
                                    })}
                                    maxLength={6}
                                    placeholder="Enter OTP"
                                    {...signInForm.register('otp', { required: true })}
                                />
                            </div>
                            <div className="invalid-feedback">
                                {signInForm.errors.otp?.type === 'required' && 'Required field.'}
                            </div>
                        </div>

                        <div className="form-group account-menu__form-button">
                            <button
                                type="button"
                                className={classNames('btn', 'btn-secondary', 'btn-sm', {
                                    'btn-loading': sendOTPForm.submitInProgress,
                                })}
                                onClick={() => sendOTPForm.submit()}
                            >
                                Resend
                            </button>
                            <button
                                type="submit"
                                className={classNames('btn', 'btn-primary', 'btn-sm', {
                                    'btn-loading': signInForm.submitInProgress,
                                })}
                            >
                                Login
                            </button>
                        </div>
                    </form>
                ) : (
                    <form className="account-menu__form" onSubmit={sendOTPForm.submit}>
                        <div className="account-menu__form-title">Log In to Your Account</div>
                        {sendOTPForm.serverError && (
                            <div className="alert alert-xs alert-danger mt-n2">
                                {sendOTPForm.serverError === 'User already exist in group other than B2C' ? (
                                    <>
                                        You are Already registered on
                                        <a href={'https://business.vmotive.in/splash'} className="account-menu__anchor">
                                            Business Portal
                                        </a>
                                    </>
                                ) : (
                                    sendOTPForm.serverError
                                )}
                            </div>
                        )}
                        <div className="form-group">
                            <label htmlFor="header-signin-email" className="sr-only">
                                Phone No.
                            </label>
                            <input
                                id="header-signin-email"
                                className={classNames('form-control', 'form-control-sm', {
                                    'is-invalid': sendOTPForm.errors.phone,
                                })}
                                placeholder="Phone No."
                                maxLength={10}
                                {...sendOTPForm.register('phone', {
                                    required: true,
                                    validate: { phone: validatePhone },
                                })}
                            />
                            <div className="invalid-feedback">
                                {sendOTPForm.errors.phone?.type === 'required' && 'Required field.'}
                            </div>
                        </div>

                        <div className="form-group account-menu__form-button">
                            <button
                                type="submit"
                                className={classNames('btn', 'btn-primary', 'btn-sm', {
                                    'btn-loading': sendOTPForm.submitInProgress,
                                })}
                            >
                                Send OTP
                            </button>
                        </div>
                    </form>
                ))}
            {user !== null && (
                <React.Fragment>
                    <AppLink href={url.accountDashboard()} className="account-menu__user" onClick={onCloseMenu}>
                        <div className="account-menu__user-avatar">{/* <AppImage src={user.avatar} /> */}</div>
                        <div className=" account-menu__user-info">
                            <div className=" account-menu__user-name">{`${user.first_name} ${user.last_name}`}</div>
                            <div className=" account-menu__user-email">{user.email}</div>
                        </div>
                    </AppLink>
                    <div className="account-menu__divider" />
                    <ul className="account-menu__links">
                        <li>
                            <AppLink href={url.accountDashboard()} onClick={onCloseMenu}>
                                Dashboard
                            </AppLink>
                        </li>
                        <li>
                            <AppLink href={url.accountGarage()} onClick={onCloseMenu}>
                                Garage
                            </AppLink>
                        </li>
                        <li>
                            <AppLink href={url.accountProfile()} onClick={onCloseMenu}>
                                Edit Profile
                            </AppLink>
                        </li>
                        <li>
                            <AppLink href={url.accountOrders()} onClick={onCloseMenu}>
                                Order History
                            </AppLink>
                        </li>
                        <li>
                            <AppLink href={url.accountAddresses()} onClick={onCloseMenu}>
                                Addresses
                            </AppLink>
                        </li>
                    </ul>
                    <div className="account-menu__divider" />
                    <ul className="account-menu__links">
                        <li>
                            <button type="button" onClick={onLogOutButtonClick}>
                                Logout
                            </button>
                        </li>
                    </ul>
                </React.Fragment>
            )}
        </div>
    );
}

export default AccountMenu;
