// react
import React from 'react';
// application
import AppLink from '~/components/shared/AppLink';
import Decor from '~/components/shared/Decor';
import FooterContacts from '~/components/footer/FooterContacts';
import FooterLinks from '~/components/footer/FooterLinks';
import FooterNewsletter from '~/components/footer/FooterNewsletter';
import { useUserToken } from '~/store/user/userHooks';

export function Footer() {
    const userToken = useUserToken();
    return (
        <div className="site-footer">
            <Decor className="site-footer__decor" type="bottom" />
            <div className="site-footer__widgets">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-xl-4">
                            <FooterContacts className="site-footer__widget" />
                        </div>
                        <div className="col-6 col-md-3 col-xl-2">
                            <FooterLinks
                                className="site-footer__widget"
                                header="Information"
                                links={[
                                    { title: 'Our Products', url: '/catalog' },
                                    {
                                        title: 'Contact Us',
                                        url: '/contact-us',
                                    },
                                    { title: 'FAQ', url: '/faq' },
                                    { title: 'Blogs', url: '/blogs/wipers' },
                                ]}
                            />
                        </div>
                        <div className="col-6 col-md-3 col-xl-2">
                            <FooterLinks
                                className="site-footer__widget"
                                header="My Account"
                                links={
                                    userToken
                                        ? [
                                              { title: 'Dashboard', url: '/account/dashboard' },
                                              { title: 'Garage', url: '/account/garage' },
                                              { title: 'Order History', url: '/account/orders' },
                                              { title: 'Address Book', url: '/account/addresses' },
                                          ]
                                        : [{ title: 'Login & Register', url: '/account/login' }]
                                }
                            />
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                            <FooterNewsletter className="site-footer__widget" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="site-footer__bottom">
                <div className="container">
                    <div className="site-footer__bottom-row">
                        <div className="site-footer__copyright">
                            {/* copyright */}
                            Powered by{' '}
                            <AppLink href="https://fixcraft.in/" target="_blank" rel="noreferrer">
                                Fixcraft
                            </AppLink>
                            {/* copyright / end */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default React.memo(Footer);
