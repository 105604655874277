// application
import { IProduct, IProductDetails } from '~/interfaces/product';
import { withClientState } from '~/store/client';
import { ICartItem, ICartState, ITax } from '~/store/cart/cartTypes';
import {
    CartAction,
    RESET_CART,
    UPDATE_CART_ID,
    UPDATE_CART,
    CART_UPDATE_QUANTITIES,
} from '~/store/cart/cartActionTypes';
import { IOrderItem } from '~/interfaces/order';

// function findItemIndex(items: IOrderItem[], product: IProductDetails | IProduct): number {
//     return items.findIndex((item) => item.sku_id === product.sku_id);
// }

// function calcSubtotal(items: ICartItem[]): number {
//     return items.reduce((subtotal, item) => subtotal + item.total, 0);
// }

// function calcQuantity(items: ICartItem[]): number {
//     return items.reduce((quantity, item) => quantity + item.quantity, 0);
// }

// function calcTotal(subtotal: number, tax: number): number {
//     return subtotal + tax;
// }

// function calcTax(items: ICartItem[]): ITax {
//     const subtotal = calcSubtotal(items);

//     return {
//         rate: 18,
//         price: subtotal * 0.18,
//     };
// }

// function addItem(state: ICartState, product: IProductDetails | IProduct, quantity: number) {
//     const itemIndex = findItemIndex(state.order_items, product);

//     let newItems;
//     let { lastItemId } = state;

//     if (itemIndex === -1) {
//         lastItemId += 1;
//         newItems = [
//             ...state.items,
//             {
//                 id: lastItemId,
//                 product: JSON.parse(JSON.stringify(product)),

//                 price: Number(product.mrp) * product.lot_size,
//                 total: Number(product.mrp) * product.lot_size * quantity,
//                 quantity,
//             },
//         ];
//     } else {
//         const item = state.items[itemIndex];

//         newItems = [
//             ...state.items.slice(0, itemIndex),
//             {
//                 ...item,
//                 quantity: item.quantity + quantity,
//                 total: (item.quantity + quantity) * item.price,
//             },
//             ...state.items.slice(itemIndex + 1),
//         ];
//     }

//     const subtotal = calcSubtotal(newItems);
//     const tax = calcTax(newItems);
//     const total = calcTotal(subtotal, tax.price);

//     return {
//         ...state,
//         lastItemId,
//         subtotal,
//         tax,
//         total,
//         items: newItems,
//         quantity: calcQuantity(newItems),
//     };
// }

// function removeItem(state: ICartState, itemId: number) {
//     const { items } = state;
//     const newItems = items.filter((item) => item.id !== itemId);

//     const subtotal = calcSubtotal(newItems);
//     const tax = calcTax(newItems);
//     const total = calcTotal(subtotal, tax.price);

//     return {
//         ...state,
//         items: newItems,
//         quantity: calcQuantity(newItems),
//         subtotal,
//         tax,
//         total,
//     };
// }

function updateQuantities(state: ICartState, sku_id: string, quantity: number) {
    const index = state.order_items.findIndex((item) => sku_id === item.sku_id);
    if (index >= 0) state.order_items[index].quantity = quantity;
    return state;
}

const initialState: ICartState = {
    lastItemId: 0,
    order_items: [],
    order_id: null,
    total_amount: 0,
    order_dt: null,
    customer_uploaded_file: null,
    order_type: 'V-Motive',
    id: 0,
    order_num: null,
};

export const CART_NAMESPACE = 'cart';

function cartBaseReducer(state = initialState, action: CartAction): ICartState {
    switch (action.type) {
        case UPDATE_CART:
            return { ...state, ...action.order };

        case CART_UPDATE_QUANTITIES:
            return updateQuantities(state, action.sku_id, action.quantity);

        case UPDATE_CART_ID:
            return { ...state, order_id: action.order_id };

        case RESET_CART:
            return initialState;

        default:
            return state;
    }
}

const cartReducer = cartBaseReducer;

export default cartReducer;
