// application
import { IUserState } from '~/store/user/userTypes';
import { USER_SET_CURRENT, UserAction, USER_SET_TOKEN, USER_SET_ADDRESS } from '~/store/user/userActionTypes';
import { withClientState } from '../client';

const initialState: IUserState = {
    current: null,
    token: null,
    currentAddress: null,
};

export const USER_NAMESPACE = 'user';

function userReducer(state = initialState, action: UserAction): IUserState {
    switch (action.type) {
        case USER_SET_CURRENT:
            return {
                ...state,
                current: action.payload,
            };
        case USER_SET_TOKEN:
            return {
                ...state,
                token: action.payload,
            };
        case USER_SET_ADDRESS:
            return {
                ...state,
                currentAddress: action.payload,
            };
        default:
            return state;
    }
}

export default withClientState(userReducer, USER_NAMESPACE);
