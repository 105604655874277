// application
import { IVehicle } from '~/interfaces/vehicle';
import {
    GARAGE_SET_CURRENT, GARAGE_SET_ITEMS, GarageAction, RESET_GARAGE, GARAGE_ADD_ITEM, GARAGE_REMOVE_ITEM,
} from '~/store/garage/garageActionTypes';
import { IGarageState } from '~/store/garage/garageTypes';
import { withClientState } from '../client';

const initialState: IGarageState = {
    items: [],
    current: null,
};

export const GARAGE_NAMESPACE = 'garage';

export const isVechileEquals = (firstVehicle:IVehicle, secondVehicle:IVehicle):boolean => {
    if (firstVehicle.brand !== secondVehicle.brand) { return false; }
    if (firstVehicle.model !== secondVehicle.model) { return false; }
    if (firstVehicle.variant !== secondVehicle.variant) { return false; }
    if (firstVehicle.year !== secondVehicle.year) { return false; }
    return true;
};

function garageReducer(state = initialState, action: GarageAction): IGarageState {
    switch (action.type) {
    case GARAGE_SET_ITEMS:
        return {
            ...state,
            items: action.payload,
        };
    case GARAGE_SET_CURRENT:
        return {
            ...state,
            current: action.payload,
        };
    case GARAGE_ADD_ITEM:
        return {
            ...state,
            items: [...state.items, action.payload],
            current: action.payload,
        };
    case GARAGE_REMOVE_ITEM:
        return {
            ...state,
            items: state.items.filter((item) => !isVechileEquals(item, action.payload)),
            current: null,
        };
    case RESET_GARAGE:
        return initialState;
    default:
        return state;
    }
}

export default withClientState(garageReducer, GARAGE_NAMESPACE);
