/* eslint-disable no-alert */

// react
import { useEffect, useMemo, useRef } from 'react';
// third-party
import { useForm } from 'react-hook-form';
// application
import { IProduct, IProductDetails } from '~/interfaces/product';
import { useAsyncAction } from '~/store/hooks';
import { useCartAddItem, useCartUpdateQuantities, useCurrentCartItem, useOrderId } from '~/store/cart/cartHooks';
import { useRouter } from 'next/router';
import { useUserToken } from '~/store/user/userHooks';
import url from '../url';
import { useQuickviewClose } from '~/store/quickview/quickviewHooks';
import queryStringBuilder from '~/helpers/queryStringBuilder';

export interface IProductForm {
    options: Record<string, any>;
    quantity: number | string;
}

export function useProductForm(product: IProductDetails | IProduct | null) {
    const cartAddItem = useCartAddItem();
    const quickviewClose = useQuickviewClose();
    const router = useRouter();
    const userToken = useUserToken();
    const orderId = useOrderId();
    const item = useCurrentCartItem(product?.sku_id || '');
    const updateCartItem = useCartUpdateQuantities();
    const methods = useForm<IProductForm>({
        defaultValues: {
            quantity: item?.quantity ?? 1,
        },
    });
    const { handleSubmit } = methods;
    const { submitCount } = methods.formState;
    const prevSubmitCount = useRef(0);

    useEffect(() => {
        if (prevSubmitCount.current !== submitCount && Object.keys(methods.formState.errors).length > 0) {
            if (methods.formState.errors.quantity) {
                alert('Please enter quantity before adding this product to cart.');
            } else if (methods.formState.errors.options) {
                alert('Please select product options before adding this product to your cart.');
            }
        }

        prevSubmitCount.current = submitCount;
    }, [submitCount, methods.formState.errors]);

    const [submit, submitInProgress] = useAsyncAction(
        async (data: IProductForm) => {
            if (!userToken) {
                quickviewClose();
                return router.push(
                    `${url.signIn()}${queryStringBuilder({
                        sku_id: product?.sku_id,
                        quantity: typeof data.quantity === 'number' ? data.quantity : 1,
                    })}`
                );
            }
            if (!product) {
                return null;
            }

            return item
                ? updateCartItem(item.id, typeof data.quantity === 'number' ? data.quantity : 1)
                : cartAddItem(product, typeof data.quantity === 'number' ? data.quantity : 1, orderId);
        },
        [orderId, product, cartAddItem]
    );

    return {
        submit: useMemo(() => handleSubmit(submit), [handleSubmit, submit]),
        submitInProgress: submitInProgress || methods.formState.isSubmitting,
        errors: methods.formState.errors,
        register: methods.register,
        watch: methods.watch,
        methods,
    };
}
