// application
import {
    accountEditProfile,
    accountSendOTP,
    accountSignIn,
    accountSignOut,
    getUserData,
    IEditProfileData,
} from '~/api';
import { clearCookieItems, setCookieItem } from '~/helpers/utils';
import { IAddress } from '~/interfaces/address';
import { IUser } from '~/interfaces/user';
import {
    USER_SET_CURRENT,
    UserSetCurrentAction,
    UserThunkAction,
    UserSetTokenAction,
    USER_SET_TOKEN,
    USER_SET_ADDRESS,
    UserSetAddressAction,
} from '~/store/user/userActionTypes';
import { resetCart } from '../cart/cartActions';
import { resetGarage } from '../garage/garageAction';

export function userSetCurrent(user: IUser | null): UserSetCurrentAction {
    const [first_name, ...rest] = user?.name?.split(' ') || ['', ''];
    return {
        type: USER_SET_CURRENT,
        payload: user ? { ...user, first_name, last_name: rest.join(' ') } : null,
    };
}
export function userSetToken(token: string | null): UserSetTokenAction {
    return {
        type: USER_SET_TOKEN,
        payload: token,
    };
}
export function userSetAddress(address: IAddress | null): UserSetAddressAction {
    return {
        type: USER_SET_ADDRESS,
        payload: address,
    };
}

export function userSignIn(phone: string, otp: string): UserThunkAction<Promise<void>> {
    return (dispatch) =>
        accountSignIn(phone, otp).then(({ token, user }) => {
            setCookieItem('userToken', token);
            setCookieItem('userId', user?.id);
            dispatch(userSetToken(token));
            dispatch(userSetCurrent(user));
        });
}
export function fetchUser(token: string): UserThunkAction<Promise<void>> {
    return (dispatch) =>
        getUserData().then((user) => {
            dispatch(userSetCurrent(user));
            dispatch(userSetToken(token));
        });
}

export function userSendOTP(phone: string): UserThunkAction<Promise<void>> {
    return () => accountSendOTP(phone).then(() => {});
}

export function userSignOut(): UserThunkAction<Promise<void>> {
    return (dispatch) =>
        accountSignOut().then(() => {
            clearCookieItems();
            dispatch(userSetToken(null));
            dispatch(userSetCurrent(null));
            dispatch(resetCart());
            dispatch(resetGarage());
        });
}

export function userEditProfile(data: IEditProfileData): UserThunkAction<Promise<void>> {
    return () => accountEditProfile(data).then(() => {});
}
