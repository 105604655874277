// application
import { AppAction } from '~/store/types';
import { IVehicle } from '~/interfaces/vehicle';

export const GARAGE_SET_ITEMS = 'GARAGE_SET_ITEMS';
export const GARAGE_ADD_ITEM = 'GARAGE_ADD_ITEM';
export const GARAGE_REMOVE_ITEM = 'GARAGE_REMOVE_ITEM';
export const RESET_GARAGE = 'RESET_GARAGE';
export const GARAGE_SET_CURRENT = 'GARAGE_SET_CURRENT';

export interface GarageSetItemsAction {
    type: typeof GARAGE_SET_ITEMS;
    payload: IVehicle[];
}

export interface GarageAddItemAction {
    type: typeof GARAGE_ADD_ITEM;
    payload: IVehicle;
}
export interface GarageRemoveItemAction {
    type: typeof GARAGE_REMOVE_ITEM;
    payload: IVehicle;
}

export interface GarageSetCurrentAction {
    type: typeof GARAGE_SET_CURRENT;
    payload: IVehicle | null;
}
export interface ResetGarageAction {
    type: typeof RESET_GARAGE;
}

export type GarageAction =
    GarageSetItemsAction |
    GarageSetCurrentAction |
    GarageAddItemAction |
    GarageRemoveItemAction |
    ResetGarageAction;

export type GarageThunkAction<T = void> = AppAction<GarageAction, T>;
