// third-party
import { toast } from 'react-toastify';
import { addOrderItem, createOrder, deleteOrderItem, getActiveOrder, updateOrderItem } from '~/api';
import { setCookieItem } from '~/helpers/utils';
import { IOrderDetails } from '~/interfaces/order';
// application
import { IProduct, IProductDetails } from '~/interfaces/product';
import {
    CART_UPDATE_QUANTITIES,
    CartThunkAction,
    CartUpdateQuantitiesAction,
    ResetCartAction,
    RESET_CART,
    UPDATE_CART_ID,
    UpdateCartIdAction,
    UpdateCartAction,
    UPDATE_CART,
} from '~/store/cart/cartActionTypes';

export function updateCartSuccess(order: IOrderDetails): UpdateCartAction {
    return {
        type: UPDATE_CART,
        order,
    };
}

export function updateCartIdSuccess(order_id: number): UpdateCartIdAction {
    return {
        type: UPDATE_CART_ID,
        order_id,
    };
}

export function resetCartSuccess(): ResetCartAction {
    return {
        type: RESET_CART,
    };
}

export function cartUpdateQuantitiesSuccess(sku_id: string, quantity: number): CartUpdateQuantitiesAction {
    return {
        type: CART_UPDATE_QUANTITIES,
        sku_id,
        quantity,
    };
}

export function cartAddItem(
    product: Partial<IProductDetails | IProduct>,
    quantity = 1,
    order_id: number | null = null
): CartThunkAction<Promise<void>> {
    // sending request to server, timeout is used as a stub
    return async (dispatch) => {
        let order;
        if (!order_id) {
            order = await createOrder();
            dispatch(updateCartSuccess(order));
            setCookieItem('orderId', order.id);
        }
        const orderUpdate = await addOrderItem({
            sku_id: product.sku_id,
            quantity,
            order: order_id || order.id,
        });
        if (product.description || product.part_description)
            toast.success(`Product "${product.description || product.part_description}" added to cart!`, {
                theme: 'colored',
            });

        dispatch(updateCartSuccess(orderUpdate));
    };
}

export function cartRemoveItem(itemId: number): CartThunkAction<Promise<void>> {
    // sending request to server, timeout is used as a stub
    return (dispatch) =>
        deleteOrderItem(itemId).then(() => {
            dispatch(fetchOrder());
        });
}

export function cartUpdateQuantities(orderItemId: number, quantity: number): CartThunkAction<Promise<void>> {
    // sending request to server, timeout is used as a stub
    return (dispatch) =>
        updateOrderItem(orderItemId, quantity).then((product) => {
            if (product.part_name)
                toast.success(`Product "${product.part_name}" added to cart!`, {
                    theme: 'colored',
                });
            dispatch(fetchOrder());
        });
}

export function resetCart(): CartThunkAction<Promise<void>> {
    // sending request to server, timeout is used as a stub
    return (dispatch) =>
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(resetCartSuccess());
                resolve();
            }, 250);
        });
}

export function fetchOrder(): CartThunkAction<Promise<void>> {
    return (dispatch) =>
        getActiveOrder().then((order) => {
            dispatch(updateCartSuccess(order));
        });
}
